import React, {Fragment, useEffect, useState} from 'react';
import PageHeader from "../../components/page-header/pageHeader";
import {useDispatch, useSelector} from "react-redux";
import {fetchContactUsRequest} from "../../redux/actions/contactUsActions";
import Loader from "../../components/loader/loader";
import {mediaBaseUrl} from "../../api/media_baseurl";
import {createFavouriteApi} from "../../api/authServices/favourite/favourite";
import {setNotifyParams} from "../../redux/actions/notifications";
import {fetchFavouriteRequest} from "../../redux/actions/favouriteActions";
import {useForm} from "react-hook-form";
import {CircularProgress, Typography} from "@material-ui/core";
import {createUserEnquariesApi} from "../../api/authServices/userEnqueries/userEnqueries";

const ContactPage = () => {
    const dispatch = useDispatch();
    const contactUsState  = useSelector(state => state.ContactUsReducer);
    const { loading, data } = contactUsState;
    const [processingRequest, setProcessingRequest] = useState(false);
    useEffect(() => {
        dispatch(fetchContactUsRequest())
    }, [])
    const [values, setStateValues] = useState({
        name: '',
        message: '',
        email:'',
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const resetForm = () => {
        reset({
            name: '',
            message: '',
            email: ''
        });
    };

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };

    const sendMessage = async () => {
        setProcessingRequest(true);
        const data = {
            full_name: values.name,
            message: values.message,
            email: values.email
        };
        await createUserEnquariesApi({
            data,
            onSuccess: res => {
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Message successfully sent',
                        type: 'success'
                    })
                );
                setProcessingRequest(false);
                resetForm();
            },
            onError: err => {
                setProcessingRequest(false);
                const { data, status } = err?.response;
                switch (status) {
                    case 400:
                        handleResponseValidationErrors(data);
                        break;
                    case 500:
                        dispatch(
                            setNotifyParams({
                                status: true,
                                message: 'Failed to send message. Please try again',
                                type: 'error'
                            })
                        );
                        break;
                    default:
                        dispatch(setNotifyParams({
                            status: true,
                            message: 'Failed to send message. Please try again',
                            type: 'error'
                        }));
                }
            }
        });
    };

    const handleResponseValidationErrors = data => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('full_name')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.name[0],
                type: 'error'
            }));
        }
        // eslint-disable-next-line no-prototype-builtins
        else if (data.hasOwnProperty('message')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.message[0],
                type: 'error'
            }));
        }
        else if (data.hasOwnProperty('email')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.email[0],
                type: 'error'
            }));
        } else {
            dispatch(setNotifyParams({
                status: true,
                message: 'Failed to process request. Try again',
                type: 'error'
            }));
        }
    };

    return (
        <Fragment>
            <PageHeader h1Text={'Contact'} h5Text={'Location &'} bgImage={`${data?.page_image}`} />
            <section className="section-padding">
                <div className="container">
                    <div className="row mb-90">
                        <div className="col-md-6 mb-30 animate-box" data-animate-effect="fadeInUp">
                            <h6>Contact Info</h6>
                            <p>{data?.info?.description}</p>
                            <p><b>Phone :</b> (+233) {data?.info?.phone}</p>
                            <p><b>Email :</b> {data?.info?.email}</p>
                            <p><b>Address :</b> {data?.info?.address}</p>
                        </div>
                        <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                            <h6>Contact Form</h6>
                            <p>let’s connect </p>
                            <form onSubmit={handleSubmit(sendMessage)} className="row">
                                <div className="col-md-6">
                                    <input
                                        error={errors.name && true}
                                        {...register('name', {
                                            required: {
                                                value: true,
                                                message: 'Name is required'
                                            }
                                        })}
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Full Name"
                                        onChange={(e) =>
                                        {handleChange(setStateValues, values, e)}
                                        }
                                    />
                                    <div style={{ color: '#9C5450' }}>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            align="center">
                                            {errors.name && errors.name.message}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        error={errors.email && true}
                                        {...register('email', {
                                            required: {
                                                value: true,
                                                message: 'Email is required'
                                            }
                                        })}
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        required=""
                                        onChange={(e) =>
                                        {handleChange(setStateValues, values, e)}
                                        }
                                    />
                                    <div style={{ color: '#9C5450' }}>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            align="center">
                                            {errors.email && errors.email.message}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <textarea
                                        error={errors.message && true}
                                        {...register('message', {
                                            required: {
                                                value: true,
                                                message: 'Message is required'
                                            }
                                        })}
                                        name="message"
                                        id="message"
                                        cols="40"
                                        rows="4"
                                        placeholder="Message"
                                        onChange={(e) =>
                                        {handleChange(setStateValues, values, e)}}
                                    >
                                    </textarea>
                                    <div style={{ color: '#9C5450' }}>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            align="center">
                                            {errors.message && errors.message.message}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button className="butn-dark mt-10" type="submit">
                                        {processingRequest && <CircularProgress size={10} />}
                                        Send Message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-30 animate-box" data-animate-effect="fadeInUp">
                            {/*<div id="jophoto-contactMap"></div>*/}
                        </div>
                    </div>
                </div>

            </section>
        </Fragment>
    )
};
export default ContactPage;
