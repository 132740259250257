import React, {Fragment, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../redux/actions/authActions";
import {loginOutApi} from "../../api/authServices/authUser/loginUser";
import {recoverUserAuth} from "../../utils/helper";
import PopupNotif2 from "../notifications/popupnotif2";
import {setNotifyParams} from "../../redux/actions/notifications";
import routes from "../../routeConstants";

const NavBar = ({data}) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const authState = useSelector(state => state.AuthReducer);
    const notifyState = useSelector(state => state.NotificationReducer);
    const {status, message} = notifyState;
    const [currentLocation, setCurrentLocation] = useState(history.location.pathname);
    const [authActions, setAuthActions] = useState([
        'Profile', 'Change password', 'Logout'
    ]);
    const { CLIENT_GALLERY, VIEW_CLIENT_GALLERY, AUTH_CHANGE_PASSWORD, FAVOURITES, FAVOURITE_DETAILS  } = routes;
    const [authRoutes, setAuthRoutes] = useState([
            CLIENT_GALLERY,
            VIEW_CLIENT_GALLERY,
            AUTH_CHANGE_PASSWORD,
            FAVOURITES,
            FAVOURITE_DETAILS
    ]);

    useEffect(() => {
        const authUser = JSON.parse(localStorage.getItem('user'));
        const currentRoute = history.location.pathname;
            if (authUser && currentRoute === routes.AUTH_LOGIN) {
                history.replace(routes.HOME)
            }

    }, []);

    useEffect(() => {
        const authUser = JSON.parse(localStorage.getItem('user'));
        const currentRoute = history.location.pathname;
        for (let i=0; i < authRoutes.length; i++) {
            if (!authUser && currentRoute === authRoutes[i]) {
                history.replace(routes.AUTH_LOGIN)
            }
        }
    }, []);

    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });

    useEffect(() => {
        const authUser = JSON.parse(localStorage.getItem('user'));
        if (!authUser) {
            // history.replace('/home');
        } else {
            recoverUserAuth(dispatch);
        }
    }, []);


    const {user} = authState;
    const viewGallery = (id, title) => {
        history.push({
            pathname: '/view-gallery',
            search: `?service-gallery=${title}`,
            state: {
                data: {
                    id,
                    title,
                    type: 'service',
                    header: 'Service'
                }
            }
        });
    };

    const getServiceData = (id, title) => {
        data = {id, title, header: 'Service'}
        localStorage.setItem('data', JSON.stringify(data));
    };

    const destroyToken = async () => {
        await loginOutApi({authToken: user.authToken});
    };

    const changePassword = (id) => {
    }
    const handleProfile = (id) => {
    }

    useEffect(() => {
        setCurrentLocation(history.location.pathname)
    }, [history.location.pathname])

    const handleLogout = () => {
        let page = history.location.pathname;
        // if (page === '/client-gallery' || page === '/auth-change-password') {
        //     page = '/home'
        // }
        localStorage.clear();
        dispatch(logoutUser());
        destroyToken();
        history.replace('/home');
    };


    const resetError = () => {
        dispatch(
            setNotifyParams({
                status: false,
                message: '',
                type: notifyState.type
            })
        );
        return setNotifyPopUp({
            status: status,
            message: message,
            popupType: notifyState.type
        });
    };

    useEffect(() => {
        setNotifyPopUp({
            status: status,
            popupType: notifyState.type,
            message: message
        });
    }, [message, notifyState.type, status]);

    const getLocation = () => {
        const path = history.location.pathname;
        if (path === '/client-gallery' ||
            path === '/favourites' ||
            path === '/favourite-details' ||
            path === '/view-client-gallery') {
            return true
        }
        return false
    }

    const getLoveStoryLocations = () => {
        const path = history.location.pathname;
        if (path === '/love-story' || path === '/view-love-story') {
            return true
        }
        return false
    }

    return (
        <Fragment>
            <nav className="navbar navbar-expand-lg">
                {/*Logo*/}
                <div className="logo-wrapper valign">
                    <div className="logo">
                        {/*<img*/}
                        {/*    height={90}*/}
                        {/*    width={90}*/}
                        {/*    alt="Ziri"*/}
                        {/*    src={'/img/app_logo.png'}*/}
                        {/*/>*/}
                        <h6><a href="/"><span style={{fontSize: 12}}>Ziri Photography</span></a></h6>
                    </div>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation"><span className="icon-bar"><i
                    className="ti-line-double"></i></span></button>
                {/*Navbar links*/}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item"><a
                            className={`nav-link ${currentLocation === '/home' ? 'active' : ''}`} href="/home">Home</a>
                        </li>
                        <li className="nav-item dropdown">
                            <span
                                className={`nav-link ${currentLocation === '/view-gallery' ? 'active' : ''}`}>
                                Portfolio
                            <i className="ti-angle-down"></i></span>
                            <ul className="dropdown-menu last">
                                {data?.map((link, index) => (
                                    <li className="dropdown-item"
                                        onClick={() => {
                                            getServiceData(link.id, link.name)
                                        }}
                                        key={index}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <a href={`/view-gallery?service-gallery=${link.name}`}>
                                            {link.name}
                                        </a>
                                    </li>
                                ))}
                                {/*<li className="dropdown-item"><a href="blog.html">Blog Page</a></li>*/}
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a
                            className={`nav-link ${getLoveStoryLocations() ? 'active' : ''}`}
                            href="/love-story">Love Story</a></li>

                        <li className="nav-item">
                            <a
                                className={`nav-link ${currentLocation === '/about-us' ? 'active' : ''}`}
                                href="/about-us">
                                About Us
                            </a></li>
                        {/*<li className="nav-item"><a className="nav-link" href="/gallery">Gallery</a></li>*/}
                        {/*{user.isLoggedIn &&*/}
                        {/*<li className="nav-item"><a*/}
                        {/*    className={`nav-link ${getLocation() ? 'active' : ''}`}*/}
                        {/*    href="/client-gallery">Client Gallery</a></li>*/}
                        {/*}*/}
                        <li className="nav-item">
                            <a
                            className={`nav-link ${currentLocation === '/contact-us' ? 'active' : ''}`}
                            href="/contact-us">Contact Us
                            </a>
                        </li>
                            <li className="nav-item">
                                <a className={`nav-link ${currentLocation === "/galleries" ? 'active' : ''}`} href="/galleries">
                                    Client Galleries
                                </a>
                            </li>

                        {/*{user.isLoggedIn && (*/}
                        {/*    <li className="nav-item dropdown"><span className="nav-link">{user.userDetail.username}<i*/}
                        {/*        className="ti-angle-down"></i></span>*/}
                        {/*        <ul className="dropdown-menu last">*/}
                        {/*            /!*<li className="dropdown-item" onClick={() => {*!/*/}
                        {/*            /!*    handleProfile(user.id)*!/*/}
                        {/*            /!*}} style={{cursor: 'pointer'}}>*!/*/}
                        {/*            /!*    Profile*!/*/}
                        {/*            /!*</li>*!/*/}

                        {/*            <li className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {changePassword(user.id)}}>*/}
                        {/*                <a href="/auth-change-password">Change Password</a>*/}
                        {/*            </li>*/}
                        {/*            <li className="dropdown-item" onClick={() => {*/}
                        {/*                handleLogout(user.id)*/}
                        {/*            }} style={{cursor: 'pointer'}}>*/}
                        {/*                Logout*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </li>*/}
                        {/*)*/}
                        {/*}*/}
                    </ul>
                </div>
            </nav>
            <PopupNotif2
                activate={notifyPopUp.status}
                messageContent={notifyPopUp.message}
                popupType={notifyPopUp.popupType}
                resetError={() => resetError()}
            />

        </Fragment>
    );
};
export default NavBar;
