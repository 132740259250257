import { CAROUSEL } from '../actionTypes';
import {fetchCarouselApi} from "../../api/authServices/carousel/carousel";
import { dataFetching } from './loaderAction';

export const fetchCarousel = () => ({
  type: CAROUSEL.FETCH_CAROUSEL_DATA
});

export const fetchCarouselSuccess = data => ({
  type: CAROUSEL.FETCH_CAROUSEL_SUCCESS,
  payload: {
    data
  }
});

export const fetchCarouselFail = err => ({
  type: CAROUSEL.FETCH_CAROUSEL_FAIL,
  payload: {
    err
  }
});

export const fetchCarouselRequest = () => {
  return dispatch => {
    dispatch(dataFetching(true))
    dispatch(fetchCarousel());
    fetchCarouselApi()
      .then(res => {
        dispatch(fetchCarouselSuccess(res.data));
        dispatch(dataFetching(false))

      })
      .catch(err => {
        dispatch(fetchCarouselFail(err));
        dispatch(dataFetching(false))

      });
  };
};
