import { LOVE_STORY_GALLERY } from '../redux/actionTypes';

const initialState = {
  loading: false,
  data: [],
  error: false
};

export default function loveStoryGalleryReducer(state = initialState, action) {
  switch (action.type) {
    case LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      };
    case LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
