import { FAVOURITE_GALLERY } from '../redux/actionTypes';

const initialState = {
    loading: false,
    data: [],
    error: false
};

export default function favouriteGalleryReducer(state = initialState, action) {
    switch (action.type) {
        case FAVOURITE_GALLERY.FETCH_FAVOURITE_GALLERY_DATA:
            return {
                ...state,
                loading: true,
                data: [],
                error: false
            };
        case FAVOURITE_GALLERY.FETCH_FAVOURITE_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data
            };
        case FAVOURITE_GALLERY.FETCH_FAVOURITE_GALLERY_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
}
