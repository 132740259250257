import { LOVE_STORY } from '../actionTypes';
import { fetchLoveStoryApi } from '../../api/authServices/loveStories/loveStories';
import { dataFetching } from './loaderAction';

//Get all gallery links
export const fetchLoveStory = () => ({
  type: LOVE_STORY.FETCH_LOVE_STORY_DATA
});

export const fetchLoveStorySuccess = data => ({
  type: LOVE_STORY.FETCH_LOVE_STORY_SUCCESS,
  payload: {
    data
  }
});

export const fetchLoveStoryFail = err => ({
  type: LOVE_STORY.FETCH_LOVE_STORY_FAIL,
  payload: {
    err
  }
});

export const fetchLoveStoryRequest = () => {
  return dispatch => {
    dispatch(dataFetching(true))
    dispatch(fetchLoveStory());
    fetchLoveStoryApi()
      .then(res => {
        dispatch(fetchLoveStorySuccess(res.data));       
         dispatch(dataFetching(false))
      })
      .catch(err => {
        dispatch(fetchLoveStoryFail(err.message));
        dispatch(dataFetching(false))

      });
  };
};
