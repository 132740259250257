import AxiosService from "../axiosService";
import baseUrl from "../BaseUrl";

export const fetchPublicGalleryApi = async data => {
    return await AxiosService.get(`${baseUrl}/photogallery/public-gallery/${data.gallery}/`)
        .then(res => res)
        .catch(err => err);
};

export const authenticatePublicGalleryApi = async ({
data,
onSuccess,
onError
}) => {
    const formData = new FormData();
    formData.append('password', data.password);
    return await AxiosService.post(
        `${baseUrl}/photogallery/authenticate-public-photo/`,
        formData
    )
        .then(res => onSuccess && onSuccess(res))
        .catch(err => onError && onError(err));
};