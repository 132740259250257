import React, {Fragment} from 'react';
import OwlCarousel from "react-owl-carousel";

const Testimonials = () => {
    return (
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-30"><span className="section-subtitle">What people are saying</span>
                        <h2 className="section-title">Testimonials</h2>
                    </div>
                </div>
                    <div className="testimonails bg-img section-padding" data-overlay-dark="5"
                         data-background="img/1920x700.jpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center over  mt-10 mb-10">
                                        <OwlCarousel
                                            items={1}
                                            loop
                                            autoplay
                                            margin={0}
                                            dots={true}
                                            smartSpeed={500}
                                            className="owl-carousel owl-theme"
                                        >
                                        <div className="citem">
                                            <div className="client-img"><img src="img/250x250.jpg" alt=""/></div>
                                            <h5>Freida & Pablo</h5>
                                            <h6>Wedding Shooting</h6>
                                            <p>Fusce blandit leo quis nulla congue dictum a ac nulla. Mauris a turpis id ligula
                                                auctor mattis. Suspendisse purus the justo hendrerit posuere lacinia dui. Nam
                                                lorem risus vel turpis pretium dignissim fermentum enim.</p>
                                        </div>
                                        <div className="citem">
                                            <div className="client-img"><img src="img/250x250.jpg" alt=""/></div>
                                            <h5>Olivia & Enrico</h5>
                                            <h6>Wedding Shooting</h6>
                                            <p>Fusce blandit leo quis nulla congue dictum a ac nulla. Mauris a turpis id ligula
                                                auctor mattis. Suspendisse purus the justo hendrerit posuere lacinia dui. Nam
                                                lorem risus vel turpis pretium dignissim fermentum enim.</p>
                                        </div>
                                        </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </Fragment>
    )
};

export default Testimonials;