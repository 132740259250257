import React, { Fragment } from 'react';


const Socials = () => {
    return (
        <Fragment>
             <div className="social">
                <a href="https://twitter.com/ziriphotography"><i className="ti-twitter-alt"></i></a>
                <a href="https://www.facebook.com/ziriphoto"><i className="ti-facebook"></i></a>
                <a href="https://www.instagram.com/ziriphotography/"><i className="ti-instagram"></i></a>
                <a href="https://www.linkedin.com/company/78340211/admin/"><i className="ti-linkedin"></i></a>
            </div>
        </Fragment>
    )
}
export default Socials;