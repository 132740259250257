import { CONTACT_US } from '../actionTypes';
import { fetchContactUsApi } from '../../api/authServices/contactUs/contactInfo';
import { dataFetching } from './loaderAction';

export const fetchContactUs = () => ({
  type: CONTACT_US.FETCH_CONTACT_DATA
});

export const fetchContactUsSuccess = data => ({
  type: CONTACT_US.FETCH_CONTACT_SUCCESS,
  payload: {
    data
  }
});

export const fetchContactUsFail = err => ({
  type: CONTACT_US.FETCH_CONTACT_FAIL,
  payload: {
    err
  }
});

export const fetchContactUsRequest = data => {
  return dispatch => {
    dispatch(dataFetching(true))
    dispatch(fetchContactUs());
    fetchContactUsApi(data)
      .then(res => {
        dispatch(fetchContactUsSuccess(res.data));
        dispatch(dataFetching(false))
      })
      .catch(err => {
        dispatch(fetchContactUsFail(err.message));
        dispatch(dataFetching(false))
      });
  };
};
