import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ResetPasswordForm from "./modal/resetPasswordForm";

const ResetPassword = (props) => {
    const dispatch = useDispatch();

    return (
        <Fragment>
            <section className="section-padding mt-100">
                <div className="container">
                    <div className="row mb-90">
                        <div className="col-md-3 mb-30">

                        </div>
                        <div className="col-md-6">
                            <ResetPasswordForm />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};
export default ResetPassword;