import React, {Fragment, useEffect, useState} from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import useStyles from '../../../modal/style';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PopupNotif2 from "../../../notifications/popupnotif2";
import {setNotifyParams} from "../../../../redux/actions/notifications";
import {generateGalleryLinkApi} from "../../../../api/authServices/sharedLinks/sharedLinks";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopy from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

const ShareGalleryForm = ({
  handleClose,
  editCategory,
  setEditCategory,
  categoryDetails,
  open,
  galleries,
  clientGalleryId
}) => {
  const authState = useSelector(state => state.AuthReducer);
  const { user } = authState;
  const [values, setStateValues] = useState({
    password: editCategory ? categoryDetails.password : '',
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copyPin, setCopyPin] = useState(false);

  const [linkDetails, setLinkDetails] = useState({
    link: '',
    pin: ''
  });
  const [generatedUrl, setGeneratedUrl] = useState('');

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
    setPassword(!password)

  }
  const [notifyPopUp, setNotifyPopUp] = useState({
    status: false,
    popupType: 'info',
    message: 'Cannot tell. Please start process again'
  });
  const resetError = () =>
      setNotifyPopUp({
        status: false,
        message: '',
        popupType: notifyPopUp.popupType
      });
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const dispatch = useDispatch();

  const handleModalClose = () => {
    if (editCategory) {
      setEditCategory(false);
    }
    resetForm();
    handleClose();
  };
  const resetForm = () => {
    reset({
      password: '',
    });
  };

  const createGalleryLink = async () => {
    setProcessingRequest(true);
    const data = {
      password: password,
      gallery: galleries,
      clientGallery: clientGalleryId,
      authToken: user.authToken
    };
    await generateGalleryLinkApi({
      data,
      onSuccess: res => {
        dispatch(
            setNotifyParams({
              status: true,
              message: 'Gallery link generated',
              type: 'success'
            })
        );
        setProcessingRequest(false);
        setLinkDetails({
          ...linkDetails,
          link: res.data.link,
          pin: res.data.pin

        })
        resetForm();
      },
      onError: err => {
        setProcessingRequest(false);
        dispatch(
            setNotifyParams({
              status: true,
              message: 'Gallery link generation failed',
              type: 'error'
            })
        );
      }
    });
  };


  const handleOnSubmit = async () => {
      await createGalleryLink();
  };

  const handleChange = (setStateValues, values, e) => {
    const { name, value } = e.target;
    setStateValues({
      ...values,
      [name]: value
    });
  };

  const handleCloseModalAction = (
    setStateValues,
    values,
    file,
    toggleModal
  ) => {
    setStateValues({
      // clear file to reset the form
      ...values,
      file: null
    });
    toggleModal();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
  
      >
        {/* <CustomDialogTitle
          titleText={editCategory ? 'Edit favourite' : 'Add new favourite'}
          toggleModal={() => {
            handleClose();
          }}
          hasCloseIcon
        /> */}
        <DialogTitle>
          <div className='row'>
            <div className='col-md-6 text-left'>
            <h6>{editCategory ? 'Edit Favourite' : 'Share Gallery'}</h6>
            </div>
            <div className='col-md-6 text-right'>
            <span className='text-muted' style={{cursor: 'pointer'}}>
          <Close onClick={handleClose} />
        </span>
              </div>
          </div>
          
        </DialogTitle>
   
          <DialogContent dividers>
              <form onSubmit={handleSubmit(handleOnSubmit)}  class="row">
                <div className="col-md-12">
                  <label>
                  <input
                      type={'checkbox'}
                      onChange={handleCheckChange}
                      value={checked}
                  />
                     Protect gallery with password
                  </label>
                </div>
                  <div class="col-md-12 text-right">
                      <button
                          disabled={processingRequest}
                          className="butn-dark mt-10"
                          type="submit"
                      >
                        {processingRequest && <CircularProgress size={15} style={{color: 'white'}} />}
                        Generate Link
                      </button>
                  </div>
              </form>
          </DialogContent>
          <DialogActions>
            <div className="col-md-12">
              {linkDetails?.link &&
                <div style={{color: '#9C5450'}}>
                 <Typography
                    variant="caption"
                    gutterBottom
                    align="center">
                  <span><b style={{color: 'black'}}>Link</b>: {linkDetails.link}
                     <span style={{paddingLeft: 10}}> <CopyToClipboard text={linkDetails.link}
                        onCopy={() => setCopied(true)}>
                        <Tooltip title="Copy">
                        <FileCopy style={{cursor: 'pointer'}} />
                        </Tooltip>
                      </CopyToClipboard>
                      </span>
                          
                  </span>
                </Typography>
                <br/>
                <span style={{color: 'black'}}>{copied && 'Copied!'}</span>
                
              </div>}
              {linkDetails?.pin &&
              <div style={{color: '#9C5450'}}>
                 <Typography
                    variant="caption"
                    gutterBottom
                    align="center">
                  <span>
                  <b style={{color: 'black'}}>Password: </b> {linkDetails.pin}
                    <span style={{paddingLeft: 10}}>
                    <CopyToClipboard text={linkDetails.pin}
                      onCopy={() => setCopyPin(true)}>
                          <Tooltip title="Copy">
                          <FileCopy style={{cursor: 'pointer'}} />
                          </Tooltip>                  
                    </CopyToClipboard>
                    </span>
                  </span>
                </Typography>
                <br />
                <span style={{color: 'black'}}>{copyPin && 'Copied!'}</span>
              </div>}
             

            </div>
          </DialogActions>
        <PopupNotif2
            activate={notifyPopUp.status}
            messageContent={notifyPopUp.message}
            popupType={notifyPopUp.popupType}
            resetError={() => resetError()}
        />
      </Dialog>
    </Fragment>
  );
};

export default ShareGalleryForm;
