import React, {Fragment, useEffect} from "react";
import PageHeader from "../../components/page-header/pageHeader";
import AboutUs from "../home/coponents/aboutUs";
import Testimonials from "../home/coponents/testimonials";
import TheTeam from "../../components/team/theTeam";
import {useDispatch, useSelector} from "react-redux";
import {fetchAboutRequest} from "../../redux/actions/aboutActions";
import {mediaBaseUrl} from "../../api/media_baseurl";
import Loader from "../../components/loader/loader";
import { dataFetching } from "../../redux/actions/loaderAction";


const AboutPage = () => {
    const dispatch = useDispatch();
    const aboutState  = useSelector(state => state.AboutReducer);
    const { loading, data } = aboutState;
    useEffect(() => {
        dispatch(fetchAboutRequest())
    }, [])
    return (
        <Fragment>

                    <PageHeader h1Text={'About Us'} h5Text={'Welcome'} bgImage={`${data?.page_image}`} />
                    <AboutUs data={data?.about} status={false}/>
                    { data?.testimon &&  <Testimonials data={data?.testimon} /> }
                     <TheTeam data={data?.team} />
            {/*img/1920x700.jpg*/}
        </Fragment>
    )
};
export default AboutPage;
