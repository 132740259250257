import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './AppButton.style';
import { CircularProgress } from '@material-ui/core';

const AppButton = ({ classes = {}, loading, ...props }) => {
  const btnClasses = useStyles();
  const { root, ...rest } = classes;
  const allClasses = { ...btnClasses, ...rest };
  return (
    <Button
      {...props}
      classes={{ root: btnClasses.root, ...allClasses }}
      startIcon={loading ? <CircularProgress size={20} style={{color: 'white'}} /> : null}
    />
  );
};

AppButton.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool
};

export default AppButton;
