import { FAVOURITE } from '../actionTypes';
import { dataFetching } from './loaderAction';
import {
  fetchFavouriteApi,
  fetchUserFavouriteApi,
  fetchUserFavouriteGalleriesApi
} from '../../api/authServices/favourite/favourite';

export const fetchFavourite = () => ({
  type: FAVOURITE.FETCH_FAVOURITE_DATA
});

export const fetchFavouriteSuccess = data => ({
  type: FAVOURITE.FETCH_FAVOURITE_SUCCESS,
  payload: {
    data
  }
});

export const fetchFavouriteFail = err => ({
  type: FAVOURITE.FETCH_FAVOURITE_FAIL,
  payload: {
    err
  }
});

export const fetchFavouriteRequest = data => {
  return dispatch => {
    // dispatch(dataFetching(true))
    dispatch(fetchFavourite());
    fetchUserFavouriteApi(data)
      .then(res => {
        dispatch(fetchFavouriteSuccess(res.data));
        dispatch(dataFetching(false));
      })
      .catch(err => {
        dispatch(fetchFavouriteFail(err.message));
        dispatch(dataFetching(false))
      });
  };
};
