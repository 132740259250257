import React, {Fragment, useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import useStyles from '../../../modal/style';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle } from '@material-ui/core';
import { Close, FavoriteBorderOutlined } from '@material-ui/icons';
import { fetchFavouriteRequest } from '../../../../redux/actions/favouriteActions';
import {
    createFavouriteApi,
    updateFavouriteApi,
    updateFavouriteGalleryApi
} from '../../../../api/authServices/favourite/favourite';
import PopupNotif2 from "../../../notifications/popupnotif2";
import {setNotifyParams} from "../../../../redux/actions/notifications";
import {generateGalleryLinkApi} from "../../../../api/authServices/sharedLinks/sharedLinks";
import {fetchFavouriteGalleryRequest} from "../../../../redux/actions/favouriteGalleryActions";

const CreateFavouriteForm = ({
  handleClose,
  editCategory,
  setEditCategory,
  categoryDetails,
  open,
  galleries
}) => {
    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;
    const [values, setStateValues] = useState({
        name: editCategory ? categoryDetails.name : '',
        description: editCategory ? categoryDetails.description : '',
        user: user.userDetail.id
    });
    const [processingRequest, setProcessingRequest] = useState(false);
    const [checked, setChecked] = useState(false);
    const [password, setPassword] = useState(false);

    const handleCheckChange = (event) => {
        setChecked(event.target.checked);
        setPassword(!password)

    }
    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });
    const resetError = () =>
        setNotifyPopUp({
            status: false,
            message: '',
            popupType: notifyPopUp.popupType
        });
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const dispatch = useDispatch();

    const handleModalClose = () => {
        if (editCategory) {
            setEditCategory(false);
        }
        resetForm();
        handleClose();
    };
    const resetForm = () => {
        reset({
            name: '',
            description: ''
        });
    };

    const updateFavourite = async (favouriteId) => {
        setProcessingRequest(true);
        const data = {
            name: values.name,
            id: categoryDetails.id,
            description: values.description,
            authToken: user.authToken
        };
        await updateFavouriteApi({
            data,
            onSuccess: res => {
                setProcessingRequest(false);
                setNotifyPopUp({
                    status: true,
                    popupType: 'success',
                    message: 'Favourite successfully updated'
                });
                dispatch(
                    fetchFavouriteGalleryRequest({
                        authToken: user.authToken,
                        id: user.userDetail.id
                    })
                );

                // setTimeout(() => {
                //     handleModalClose();
                // }, 3000);
            },
            onError: err => {
                const { data, status } = err.response;
                switch (status) {
                    case 400:
                        setProcessingRequest(false);
                        handleFavResValidationErrors(data);
                        break;
                    case 500:
                        setProcessingRequest(false);
                        setNotifyPopUp({
                            status: true,
                            popupType: 'error',
                            message: 'Favourite update failed'
                        });
                        break;
                    default:
                        setProcessingRequest(false);
                        setNotifyPopUp({
                            status: true,
                            popupType: 'error',
                            message: 'Favourite update failed'
                        });
                }
            }
        });
    };

    const createFavourite = async () => {
        setProcessingRequest(true);
        const data = {
            name: values.name,
            id: values.user,
            description: values.description,
            authToken: user.authToken
        };
        await createFavouriteApi({
            data,
            onSuccess: res => {
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Favourite successfully created',
                        type: 'success'
                    })
                );
                setProcessingRequest(false);
                dispatch(
                    fetchFavouriteRequest({
                    authToken: user.authToken,
                    id: user.userDetail.id
                  })
                );
                resetForm();
                // handleModalClose();
            },
            onError: err => {
                setProcessingRequest(false);
                const { data, status } = err.response;
                if (err.response) {
                    switch (err.response.status) {
                        case 400:
                            handleFavResValidationErrors(data);
                            break;
                        case 500:
                            dispatch(
                                setNotifyParams({
                                    status: true,
                                    message: 'Favourite creation failed',
                                    type: 'error'
                                })
                            );
                            break;
                        default:
                            dispatch(setNotifyParams({
                                status: true,
                                message: 'Favourite creation failed',
                                type: 'error'
                            }));
                    }
                } else {
                    dispatch(setNotifyParams({
                        status: true,
                        message: 'Favourite creation failed',
                        type: 'error'
                    }));
                }
            }
        });
    };

    const handleFavResValidationErrors = data => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('name')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.name[0],
                type: 'error'
            }));

        } else if (data.hasOwnProperty('description')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.description[0],
                type: 'error'
            }));
        } else if (data.hasOwnProperty('user')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.user[0],
                type: 'error'
            }));
        }
        else {
            dispatch(setNotifyParams({
                status: true,
                message: 'Failed to process request. Try again',
                type: 'error'
            }));
        }
    };

    const handleOnSubmit = async () => {
        if (!editCategory) {
        await createFavourite();
        }
        else {
          await updateFavourite();
        }
    };

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                classes={{ paper: classes.dialogPaper }}
                scroll="body"
            >
                <DialogTitle>
                    <div className='row'>
                        <div className='col-md-6 text-left'>
                            <h6>{editCategory ? 'Edit Favourite' : 'Create Favourite'}</h6>
                        </div>
                                        <div className='col-md-6 text-right'>
                            <span className='text-muted' style={{cursor: 'pointer'}}>
                          <Close onClick={handleClose} />
                        </span>
                        </div>
                    </div>

                </DialogTitle>

                <DialogContent dividers>
                    <form onSubmit={handleSubmit(handleOnSubmit)}  class="row">
                        <div class="col-md-12">
                            <input
                             error={errors.name && true}
                             {...register('name', {
                                 required: {
                                     value: true,
                                     message: 'Favourite is required'
                                 },
                                 maxLength: {
                                     value: 10,
                                     message: 'Favourite name can not be more than 10 characters'
                                 }
                             })}
                            defaultValue={categoryDetails?.name}
                            type="text"
                            onChange={e => handleChange(setStateValues, values, e)}
                            name="name" id="name" placeholder="name" />
                            <div style={{ color: '#9C5450' }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    align="center">
                                    {errors.name && errors.name.message}
                                </Typography>
                            </div>
                             </div>
                        <div className="col-md-12">
                            <textarea
                                rows={2}
                                error={errors.description && true}
                                {...register('description', {
                                    required: {
                                        value: false,
                                        message: 'Favourite is required'
                                    },
                                })}
                                defaultValue={categoryDetails?.description}
                                type="text"
                                onChange={e => handleChange(setStateValues, values, e)}
                                name="description" id="description" placeholder="Description(Optional)"/>
                            <div style={{color: '#9C5450'}}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    align="center">
                                    {errors.description && errors.description.message}
                                </Typography>
                            </div>
                        </div>

                        <div class="col-md-12 text-right">
                            <button
                                disabled={processingRequest}
                                className="butn-dark mt-10"
                                type="submit"
                            >
                                {editCategory ? 'Edit Favourite' : 'Create Favourite'}
                            </button>
                        </div>
                    </form>
                </DialogContent>
                <PopupNotif2
                    activate={notifyPopUp.status}
                    messageContent={notifyPopUp.message}
                    popupType={notifyPopUp.popupType}
                    resetError={() => resetError()}
                />
            </Dialog>
        </Fragment>
    );
};

export default CreateFavouriteForm;
