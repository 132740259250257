import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/loader/loader";
import {mediaBaseUrl} from "../../api/media_baseurl";
import ViewGalleries from "../service/viewGallery";
import {fetchClientPhotosRequest} from "../../redux/actions/clientPhotosActions";
import {resetImages} from "../../redux/actions/resetImages";
import {fetchFavouriteGalleryDetailRequest} from "../../redux/actions/favouriteGalleryDetail";
import {fetchFavouriteRequest} from "../../redux/actions/favouriteActions";
import {initRequest} from "../../utils/helper";
import {fetchClientGalleryRequest} from "../../redux/actions/clientGalleryActions";
import {APP_NAME} from "../../config";

const ViewClientGalleryDetails = (props) => {
    const galleryState = useSelector(state => state.ClientPhotosReducer);
    const authState = useSelector(state => state.AuthReducer);
    const resetImageState = useSelector(state => state.ResetImagesReducer);
    const { loading, data } = galleryState;
    const [fetchGallery, setFetchGallery] = useState();
    const { user } = authState;
    const dispatch = useDispatch();
    const [serviceData, setServiceData] = useState({
        id: null,
        title: '',
        description: {},
        cover_image: null
    });
    const favouriteState = useSelector(state => state.FavouriteGalleryDetailReducer);
    // useEffect(() => {
    //     if (resetImageState.resetImage) {
    //         dispatch(resetImages(false))
    //     }
    // }, [resetImageState.resetImage]);


    useEffect(() => {
        initRequest(dispatch, fetchFavouriteRequest);
    }, []);


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const favouriteId = localStorage.getItem('fav_id')
        if (user) {
            if (favouriteId) {
                dispatch(fetchFavouriteGalleryDetailRequest({
                    authToken: user.authToken,
                    id: favouriteId
                }));
            }
        }
    }, [])

    useEffect(() => {
        const serviceData = JSON.parse(localStorage.getItem('data'))
          if (serviceData?.id) {
             setServiceData({
                 ...serviceData,
                   id: serviceData.id,
                   title: serviceData.title,  
                   cover_image: serviceData.image
             });
 
          }
     }, [])

    useEffect(() => {
        if (serviceData?.id) {
            localStorage.setItem('favClientGalleryId', serviceData?.id)
            dispatch(
                fetchClientPhotosRequest({
                    id: serviceData.id,
                    authToken: user.authToken
                })
            );
        }
    
    }, [dispatch, serviceData.id]);
    return (
        <Fragment>
                <section className="section-padding mt-95">

                    <div className="container">
                        <div className="row jophoto-photos mt-15" id="jophoto-section-photos">
                            <div className="col-md-12 animated fadeInUp">
                                <div className="row">
                                    <div className="col-md-12 mb-30"><span className="section-subtitle">{serviceData.title}</span>
                                        <h4 style={{fontSize: 28}} className="section-title">{APP_NAME}</h4>
                                    </div>
                                </div>
                                <ViewGalleries
                                    id={serviceData?.id}
                                    type={'client-gallery'}
                                    title={serviceData?.title}
                                    fetchGallery={fetchGallery}
                                    data={data?.gallery}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                            {/*<header className="banner-header banner-img valign bg-img" data-overlay-dark="7"*/}
                            {/*        data-stellar-background-ratio="0.5" style={{*/}
                            {/*    backgroundImage: "url(" + `${mediaBaseUrl}${serviceData.cover_image}` + ")"}}>*/}
                            {/*    <div className="container">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-md-7 offset-md-5 text-right caption mt-60 animate-box"*/}
                            {/*                 data-animate-effect="fadeInUp">*/}
                            {/*                <h1>{serviceData.title}</h1>*/}
                            {/*                <h5>{'Gallery'}</h5>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</header>*/}

        </Fragment>
    )
};
export default ViewClientGalleryDetails;