import React, {Fragment, useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import useStyles from '../modal/style';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {useHistory} from "react-router-dom";
import PopupNotif2 from "../notifications/popupnotif2";
import {setNotifyParams} from "../../redux/actions/notifications";
import {authenticatePublicGalleryApi} from "../../api/authServices/publicGallery/publicGallery";
import {fetchPublicGallerySuccess} from "../../redux/actions/publicGalleryActions";

const PublicGalleryPasswordForm = ({
handleClose,
open,
galleries
}) => {
    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;
    const [processingRequest, setProcessingRequest] = useState(false);
    const [values, setValues] = useState({
        password: ''
    });
    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });
    const resetError = () =>
        setNotifyPopUp({
            status: false,
            message: '',
            popupType: notifyPopUp.popupType
        });
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const dispatch = useDispatch();
     const history = useHistory();
    const resetForm = () => {
        reset({
            password: '',
        });
    };

    const submit = async () => {
        setProcessingRequest(true);
        const data = {
            password: values.password,
        };
        await authenticatePublicGalleryApi({
            data,
            onSuccess: res => {
                dispatch(fetchPublicGallerySuccess(res.data));
                resetForm();
                handleClose()
            },
            onError: err => {
                setProcessingRequest(false);
                if (err.response) {
                    const { data, status } = err.response;
                    switch (status) {
                        case 400:
                            handleFavResValidationErrors(data);
                            break;
                        case 500:
                            dispatch(
                                setNotifyParams({
                                    status: true,
                                    message: 'Failed to process request',
                                    type: 'error'
                                })
                            );
                            break;
                        default:
                            dispatch(setNotifyParams({
                                status: true,
                                message: 'Failed to process request',
                                type: 'error'
                            }));
                    }
                } else {
                    dispatch(setNotifyParams({
                        status: true,
                        message: 'Failed to process request',
                        type: 'error'
                    }));
                }
            }
        });
    };

    const handleFavResValidationErrors = data => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('password')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.password[0],
                type: 'error'
            }));
        }
        else {
            dispatch(setNotifyParams({
                status: true,
                message: 'Failed to process request. Try again',
                type: 'error'
            }));
        }
    };

    const handleOnSubmit = async () => {
        await submit();
    };

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                classes={{ paper: classes.dialogPaper }}
                scroll="body"

            >
                <DialogTitle>
                    <div className='row'>
                        <div className='col-md-6 text-left'>
                            <h6>{'Unlock Gallery'}</h6>
                        </div>
                        <div className='col-md-6 text-right'>
                        <span className='text-muted' style={{cursor: 'pointer'}}>
                      <Close onClick={
                          () => {
                              history.replace('/home')
                              handleClose();
                          }} />
                    </span>
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent dividers>
                    <form onSubmit={handleSubmit(handleOnSubmit)}  className="row">
                        <div className="col-md-12">
                            <label>
                                Provide password to unlock gallery
                            </label>
                        </div>
                        <div className="col-md-12">
                            <input
                             error={errors.password && true}
                             {...register('password', {
                                 required: {
                                     value: true,
                                     message: 'Password is required'
                                 },
                             })}
                            type="password"
                            onChange={e => handleChange(setValues, values, e)}
                            name="password" id="password" placeholder="password" />
                            <div style={{ color: '#9C5450' }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    align="center">
                                    {errors.password && errors.password.message}
                                </Typography>
                            </div>
                             </div>

                        <div className="col-md-12 text-right">
                            <button
                                disabled={processingRequest}
                                className="butn-dark mt-10"
                                type="submit"
                            >
                                {'Unlock'}
                            </button>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                </DialogActions>
                <PopupNotif2
                    activate={notifyPopUp.status}
                    messageContent={notifyPopUp.message}
                    popupType={notifyPopUp.popupType}
                    resetError={() => resetError()}
                />
            </Dialog>
        </Fragment>
    );
};

export default PublicGalleryPasswordForm;
