import React, {Fragment} from 'react';

const SeeGalleriesPage = () => {

    return (
        <Fragment>
            <section  style={{marginTop: 80}}>
                <div>
                    <iframe loading="eager" height={800}  style={{ width: "100%", border: "none", overflowY: "auto" }} name="iframe1" src="https://clients.ziriphoto.com"></iframe>
                    {/*<div style={{ zIndex: 999, position:"relative", top: -250, height: 250, background: '#101010'}}></div>*/}
                </div>
            </section>
        </Fragment>
    )
}
export default SeeGalleriesPage;
