import React, {Fragment, useEffect} from 'react';
import { useSelector } from 'react-redux';
const Loader = () => {
    const loaderState  = useSelector(state => state.LoaderReducer);
    return (
        <Fragment>
            {
                loaderState?.loading && (
                    <div className="loading">
                    <div className="text-center middle">
                        <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                )
            }
         
        </Fragment>
    );
};
export default Loader;