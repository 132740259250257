import React, {Fragment, useState} from 'react';
import LeftPanel from "../left-panel/leftPanel";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {mediaBaseUrl} from "../../api/media_baseurl";

const SideBar = ({ data }) => {
const [animateH4, setAnimateH4] = useState(false);

    return (
        <Fragment>
            <header className="header slider-fade">
                <OwlCarousel
                    items={1}
                    loop
                    autoplay
                    margin={0}
                    dots={false}
                    smartSpeed={500}
                    nav
                    className="owl-carousel owl-theme"
                    navText={['<i class="ti-angle-left" aria-hidden="true"></i>', '<i class="ti-angle-right" aria-hidden="true"></i>']}
                >
                    {data?.map((car, index) => (
                        <div className="text-right item bg-img" data-overlay-dark="7" itemID={car.id} key={car.id}>
                            <img className="bg-img" src={`${mediaBaseUrl}${car.image}`} style={{width: '100%', height: 'auto'}}/>
                            <div className="v-middle caption mt-30">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-6">
                                            <div className="o-hidden">
                                                <h4>{car.header}</h4>
                                                <h1>{car.title}</h1>
                                                <p>{car.message}</p>
                                                <a href="/about-us" className={`butn butn-dark mt-30`}> <span>About Me</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
                <LeftPanel />
            </header>
        </Fragment>
    );
};
export default SideBar;
