import {Button, Tooltip, Typography} from '@material-ui/core';
import {ButtonGroup} from '@material-ui/core';
import {
    CloudDownloadOutlined,
    Delete,
    Edit,
    FavoriteOutlined,
    PhotoLibrary,
    RemoveCircleOutlined, ShareOutlined
} from '@material-ui/icons';
import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    deleteFavouriteApi,
    deletePhotoFromFavouriteApi, downloadPhotosApi,
    updateFavouriteGalleryApi
} from "../../api/authServices/favourite/favourite";
import {fetchFavouriteRequest} from "../../redux/actions/favouriteActions";
import CreateFavouriteForm from "../favourites/gallery-category/forms/createFavourite";
import AppDeleteModal from "../modal/AppDeleteModal/AppDeleteModal";
import {setNotifyParams} from "../../redux/actions/notifications";
import {fetchFavouriteGalleryDetailRequest} from "../../redux/actions/favouriteGalleryDetail";
import favouriteGalleryDetailReducer from "../../reducers/FavouriteGalleryDetailReducer";
import {fetchFavouriteGalleryRequest} from "../../redux/actions/favouriteGalleryActions";
import {initRequest} from "../../utils/helper";
import {mediaBaseUrl} from "../../api/media_baseurl";

let arr = [];
const Checkmark = ({selected}) => (
    <div
        style={
            selected
                ? {left: '4px', top: '4px', position: 'absolute', zIndex: '1'}
                : {display: 'none'}
        }>
        <svg
            style={{fill: 'white', position: 'absolute'}}
            width="24px"
            height="24px">
            <circle cx="12.5" cy="12.2" r="8.292"/>
        </svg>
        <svg
            style={{fill: '#06befa', position: 'absolute'}}
            width="24px"
            height="24px">
            <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
        </svg>
    </div>
);

const imgStyle = {
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
};
const selectedImgStyle = {
    transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
};
const cont = {
    backgroundColor: 'black',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
};

const SelectedImage = ({
index,
photo,
margin,
direction,
top,
left,
selected,
setViewerIsOpen,
setCurrentImage,
addPhotoToGallery,
type,
handleGalleryFilterAfterDelete,
typeId,
handleOpenShareModal,
token
}) => {
    const [isSelected, setIsSelected] = useState(selected);
    // const [onHover, setOnHover] = useState(false);
    const [values, setValues] = useState({});
    const imageState = useSelector(state => state.ResetImagesReducer)
    const [open, setOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [editFavourite, setEditFavourite] = useState(false);
    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;
    const dispatch = useDispatch();
    const favouriteState = useSelector(state => state.FavouriteGalleryDetailReducer);
    const { data } = favouriteState;
    const favouriteGalleryState = useSelector(state => state.FavouriteGalleryReducer);

    const [photoInFavourite, setPhotoInFavourite] = useState(false);
    const [hover, setHover] = useState(false);

    //calculate x,y scale
    const sx = (100 - (30 / photo.width) * 100) / 100;
    const sy = (100 - (30 / photo.height) * 100) / 100;
    selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

    if (direction === 'column') {
        cont.position = 'absolute';
        cont.left = left;
        cont.top = top;
    }

    // const handleImageOnHover = () => {
    //     setOnHover(!onHover)
    // }

    const onHover = () => {
        setHover(!hover);
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        const imgSel = JSON.parse(localStorage.getItem('selectedImages'));
        if (imageState.resetImage) {
            setIsSelected(false);
        }
    }, [imageState.resetImage])


        const download = (e, photo) => {
            let element = e.target;
            let file = new Blob(
                [
                    `${mediaBaseUrl}/media/gallery/land-scape/orig_image.jpg`
                ],
                { type: "image/*" }
            );
            element.href = URL.createObjectURL(file);
            element.download ="image.jpg";
            element.click();
    }

    const handleDownload = async (id) => {
        const user = JSON.parse(localStorage.getItem('user'));
        setProcessingRequest(true);
        const data = {
            id: [id],
            authToken: user.authToken
        };
        await downloadPhotosApi({
            data,
            onSuccess: res => {
                console.log(res)
            },
            onError: err => {
                console.log(err)
                setProcessingRequest(false);
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Failed to download photo',
                        type: 'error'
                    })
                );
            }
        });
    }

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    }

    const deleteFavourite = async (id) => {
        setProcessingRequest(true);
        const data = {
            id,
            authToken: user.authToken
        };
        await deleteFavouriteApi({
            data,
            onSuccess: res => {
                setProcessingRequest(false);
                handleGalleryFilterAfterDelete(photo.id);
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Favourite successfully deleted',
                        type: 'success'
                    })
                );
                initRequest(dispatch, fetchFavouriteGalleryRequest);
                //remove deleted favourite id from storage
                localStorage.removeItem('fav_id')
                toggleDeleteModal();
            },
            onError: err => {
                setProcessingRequest(false);
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Favourite deletion failed',
                        type: 'error'
                    })
                );
            }
        });
    };


    // useEffect(() => {
    //  checkIfPhotoIsAddedToAfavourite()
    // }, [data])

    const checkIfPhotoIsAddedToAfavourite = (gallery, photoId) => {
        for (let i = 0; i < gallery?.length; i++) {
            if (gallery[i].id === photoId) {
                return true;
            }
        }
       return false;
    }

    const removePhotoFromFavourite = async (id) => {
        setProcessingRequest(true);
        const data = {
            id: typeId,
            gallery: id,
            authToken: user.authToken
        };
        await deletePhotoFromFavouriteApi({
            data,
            onSuccess: res => {
                setProcessingRequest(false);
                handleGalleryFilterAfterDelete(photo.id);

                dispatch(fetchFavouriteGalleryRequest({
                    authToken: user.authToken,
                    id: user.userDetail.id
                }));
                dispatch(fetchFavouriteGalleryDetailRequest({
                    authToken: user.authToken,
                    id: typeId
                }));
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Photo successfully removed',
                        type: 'success'
                    })
                );

                toggleDeleteModal();
            },
            onError: err => {
                setProcessingRequest(false);
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Photo removal failed',
                        type: 'error'
                    })
                );
            }
        });
    };

    const getFavourite = (photo, type) => {
        setValues({
            ...values,
            id: photo.id,
            name: photo.title,
            description: photo.description,
            time_created: photo.time_created,
            type: type
        });
    }

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);


    const getFavouriteData = (id, title) => {
        const data= {id, title}
        localStorage.setItem('fav-data', JSON.stringify(data));
    };

    return (
        <Fragment>
            <div
                style={{margin, height: photo.height, width: photo.width, ...cont}}
                className={!isSelected ? 'not-selected' : ''}
                key={index}
            >
                {type =='favourite' &&
                (
                    <div className={'col-md-6 float-left'} style={{paddingLeft: 2, paddingTop: 5}}>
                        <div className={'float-left'} style={{paddingLeft:2}}>
                                    <span className={'float-left'} style={{
                                        color: '#FFFFFF',
                                        fontSize:14}}>
                                    {photo.title}
                                        <b style={{color: '#9A534F'}}>|</b>
                                    <PhotoLibrary style={{fontSize:15, paddingTop:5}}/>
                                        {favouriteGalleryState.data[index].galleries?.length}
                                </span>
                        </div>
                    </div>
                )
                }

                {/*<Checkmark selected={!!isSelected} />*/}
                <a href={
                    type === 'client-gallery' || type === 'public-detail' ||
                    type === 'favourite-detail'
                        ? 'javascript: void(0);' :`/favourite-details?favourite=${photo.title}`}

                   onClick={
                       type === 'client-gallery' ?
                           null : () => {getFavouriteData(photo.id, photo.title)}}>
                <img
                    alt={photo.title}
                    style={
                        isSelected ? {...imgStyle, ...selectedImgStyle} : {...imgStyle}
                    }
                    {...photo}
                    onClick={() => {
                        if (
                            type === 'client-gallery' || type === 'public-detail' ||
                            type === 'favourite-detail'
                        ) {
                            setViewerIsOpen(true);
                            setCurrentImage(index);
                        }
                    }}
                />
                </a>
                 {/*<style>{`.not-selected:hover{outline:2px solid #06befa}`}</style>*/}
                {/* {onHover && ( */}
                <div style={{
                    position: 'absolute',
                    background: type === 'favourite' ? 'rgba(16,16,16,0.1)' : 'rgba(16,16,16,0.1)',
                    bottom: 0,
                    left: 0,
                    textAlign: type === 'favourite' ? 'right' : '',
                    width: photo.width,
                    // padding: 5,
                    marginLeft: 0.5
                }}>
                    {type === "client-gallery" &&
                    <div className={'row'}>
                        <div className={'col-md-12 text-right'}>
                            <ButtonGroup style={{paddingTop: 10}}>
                                <Tooltip title="Add to favourite">
                                    <FavoriteOutlined
                                        style={{
                                        color: checkIfPhotoIsAddedToAfavourite(data?.galleries, photo.id) ? '#9a534f': '#ffffff'
                                        }}
                                        onClick={() => {
                                        addPhotoToGallery(photo.id);
                                    }}/>
                                </Tooltip>
                                <Tooltip title="Share">
                                    <ShareOutlined
                                        style={{
                                        color: '#ffffff'
                                        }}
                                          onClick={() => {
                                              handleOpenShareModal([photo.id])
                                          }}
                                    />
                                </Tooltip>
                                <Tooltip title="Download image" style={{marginRight: 10}}>
                                    <a href={`${mediaBaseUrl}/userportal/download-photo/user/${user.userDetail.id}/photo/${photo.id}/`}>
                                        <CloudDownloadOutlined style={{color: 'white'}}/>
                                    </a>
                                </Tooltip>
                            </ButtonGroup>
                        </div>
                    </div>
                    }

                    {type === "public-detail" &&
                    <div className={'row'}>
                        <div className={'col-md-12 text-right'}>
                            <ButtonGroup style={{paddingTop: 10}}>
                                <Tooltip title="Download image" style={{marginRight: 10}}>
                                    <a href={`${mediaBaseUrl}/photogallery/download-photo/photo/${photo.id}/token/${token}/`}>
                                        <CloudDownloadOutlined style={{color: 'white'}}/>
                                    </a>
                                </Tooltip>
                            </ButtonGroup>
                        </div>
                    </div>
                    }

                    {type === "favourite" &&
                    <div className={'row'}>
                                <div className={'col-md-12 float-right'} style={{ paddingLeft: 20, paddingTop: 5, paddingRight: 20,}}>
                                    <ButtonGroup>
                                        <Tooltip title="Edit favourite">

                                            <Edit className={'text-muted'} style={{
                                                fontWeight: 'bolder',
                                                fontSize: 22}}
                                                  onClick={() => {
                                                      getFavourite(photo, 'favourite');
                                                      handleOpen();
                                                  }}/>
                                        </Tooltip>
                                        <Tooltip title="Delete favourite">
                                            <Delete  style={{color: '#ffffff', fontWeight: 'bolder', fontSize: 22}}
                                                     onClick={() => {
                                                         getFavourite(photo, 'favourite');
                                                         toggleDeleteModal();
                                                     }}/>
                                        </Tooltip>
                                    </ButtonGroup>
                                </div>
                    </div>
                    }
                    {type === "favourite-detail" &&
                    <div className={'row'}>
                    <div className={'col-md-12 text-right'}>
                        <ButtonGroup style={{paddingTop: 10}}>
                            <Tooltip title="Remove from favourite">
                                <RemoveCircleOutlined className={'text-muted'} onClick={() => {
                                    getFavourite(photo, 'photo');
                                    toggleDeleteModal()
                                }}/>
                            </Tooltip>
                            <Tooltip title="Share">
                                <ShareOutlined
                                    style={{
                                        color: '#ffffff'
                                    }}
                                    onClick={() => {
                                        handleOpenShareModal([photo.id])
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Download image">
                                <a href={`${mediaBaseUrl}/userportal/download-photo/user/${user.userDetail.id}/photo/${photo.id}/`}>
                                <CloudDownloadOutlined style={{color: 'white'}}/>
                                </a>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                    </div>
                    }
                </div>
                {/* )} */}
            </div>

            {open && <CreateFavouriteForm
             editCategory={true}
             setEditCategory={setEditFavourite}
             handleClose={handleClose}
             open={open}
             categoryDetails={values}
             />}
            <AppDeleteModal
                open={deleteModal}
                title={<Typography variant={'h6'}>Delete Favourite</Typography>}
                cancelText="No, Cancel"
                confirmText="Yes, Delete"
                toggleModal={() => {
                    toggleDeleteModal();
                }}
                heading={type === 'favourite' ? `You are about to delete ${values.type} ${values.name}` :
                    `You are about to delete this ${values.type} from favourite`
                }
                content={<span> Are you sure you want to delete?</span>}
                loading={processingRequest}
                confirmAction={() => {
                    if (type === 'favourite') {
                        deleteFavourite(values.id);
                    }
                    if (type === 'favourite-detail') {
                        removePhotoFromFavourite(values.id)
                    }
                }} // action to delete item
            />
        </Fragment>
    );
};

export default SelectedImage;
