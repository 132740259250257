import React, {lazy, Suspense, useEffect} from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import SiteHome from "./pages/home/home";
import Loader from "./components/loader/loader";
import NavBar from "./components/layouts/navBar";
import Footer from "./components/footer/footer";
import AboutPage from "./pages/about/about";
import ServicePage from "./pages/service/service";
import ContactPage from "./pages/contact/contact";
import LoveStoryPage from "./pages/love-story/loveStory";
import {useDispatch, useSelector} from "react-redux";
import {fetchCarouselRequest} from "./redux/actions/carouselActions";
import ViewLoveStoryDetails from "./pages/love-story/viewLoveStoryGallery";
import ViewClientGalleryDetails from "./pages/clientGallery/viewClientGallery";
import ClientGalleryPage from "./pages/clientGallery/clientGallery";
import LoginPage from "./pages/auth/login";
import Favourites from "./pages/favourites/favourites";
import FavouriteDetails from "./pages/favourites/favouriteDetail";
import ChangePasswordPage from "./pages/auth/changePassword";
import SharedGalleryDetails from "./pages/publicGallery/viewSharedGallery";
import PageNotFound from "./pages/pageNotFound/pageNotFound";
import routes from "./routeConstants";
import ResetPassword from "./pages/auth/resetPassword";
import SeeGalleriesPage from "./pages/seeGalleries";

const Routes = () => {
  const location = useLocation();
    const dispatch = useDispatch();
    const carouselState  = useSelector(state => state.CarouselReducer);
    const { data } = carouselState;
    const loaderState  = useSelector(state => state.LoaderReducer);
    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;

    useEffect(() => {
        dispatch(fetchCarouselRequest())
    }, [])

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <ThemeProvider>
      {/*<Loader/>*/}
       {loaderState?.loading && <Loader /> }
      {/*Navbar*/}
         {!loaderState?.loading && <NavBar data={data?.service} />}
      {/*<AnimatePresence>*/}
        {/*<Suspense*/}
        {/*  fallback={*/}
        {/*    <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">*/}
        {/*      <div className="w-50 mx-auto">*/}
        {/*         <Loader />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  }>*/}

          <Switch>
          <Redirect exact from="/" to={routes.HOME} />
            <Route path={[routes.HOME]}>
              {/* <PresentationLayout> */}
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/" component={SiteHome} />
                  </motion.div>
                </Switch>
              {/* </PresentationLayout> */}
            </Route>
            <Route
              path={[
                routes.ABOUT_US,
                routes.VIEW_LOVE_STORY,
                routes.VIEW_CLIENT_GALLERY,
                routes.VIEW_GALLERY,
                routes.CLIENT_GALLERY,
                !user.isLoggedIn ? '' : routes.FAVOURITE_DETAILS,
                routes.FAVOURITES,
                routes.CONTACT_US,
                routes.LOVE_STORY,
                routes.AUTH_LOGIN,
                routes.AUTH_CHANGE_PASSWORD,
                routes.PHOTOGALLERY,
                routes.BLOG,
                routes.PAGE_NOT_FOUND,
                routes.RESET_PASSWORD_FORM,
                routes.SEE_GALLERIES,
              ]}>
              {/*<LeftSidebar>*/}
               <Switch location={location} key={location.pathname}>
                 <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    {/* <Route path="/home" component={SiteHome} /> */}
                     <Route path={routes.AUTH_LOGIN} component={LoginPage} />
                     <Route path={routes.ABOUT_US} component={AboutPage} />
                    <Route path={routes.VIEW_GALLERY} component={ServicePage} />
                    <Route path={routes.VIEW_LOVE_STORY} component={ViewLoveStoryDetails} />
                    <Route path={routes.CONTACT_US} component={ContactPage} />
                    <Route path={routes.LOVE_STORY} component={LoveStoryPage} />
                     <Route path={routes.PHOTOGALLERY} component={SharedGalleryDetails} />
                     <Route path={routes.RESET_PASSWORD_FORM} component={ResetPassword} />
                     <Route path={routes.SEE_GALLERIES} component={SeeGalleriesPage} />
                     {
                       user.isLoggedIn && (
                           <>
                         <Route path={routes.CLIENT_GALLERY} component={ClientGalleryPage} />
                         <Route path={routes.VIEW_CLIENT_GALLERY} component={ViewClientGalleryDetails} />
                         <Route path={routes.AUTH_CHANGE_PASSWORD} component={ChangePasswordPage} />
                         <Route path={routes.FAVOURITES} component={Favourites} />
                         <Route path={routes.FAVOURITE_DETAILS} component={FavouriteDetails} />
                           </>
                       )
                     }
                 </motion.div>
                </Switch>
              {/*</LeftSidebar>*/}
            </Route>
            <Route component={PageNotFound} />
          </Switch>
        {!loaderState?.loading &&

        <Footer /> }
      {/*  </Suspense>*/}
      {/*</AnimatePresence>*/}
    </ThemeProvider>
  );
};

export default Routes;
