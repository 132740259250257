import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import PageNotFound from "./pages/pageNotFound/pageNotFound";

// import ScrollToTop from './utils/ScrollToTop';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import { library } from '@fortawesome/fontawesome-svg-core';

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {/*<BrowserRouter basename="/admin-account/">*/}
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
