import { CONTACT_US } from '../redux/actionTypes';

const initialState = {
  loading: false,
  data: [],
  error: false
};

export default function contactPageReducer(state = initialState, action) {
  switch (action.type) {
    case CONTACT_US.FETCH_CONTACT_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      };
    case CONTACT_US.FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case CONTACT_US.FETCH_CONTACT_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
