import React, {Fragment} from 'react';
import OwlCarousel from "react-owl-carousel";
import {mediaBaseUrl} from "../../../api/media_baseurl";
import {useHistory} from "react-router-dom";

const Service = ({ data }) => {
    const history = useHistory();

    const viewGallery = (id, title) => {
        history.push({
            pathname: '/view-gallery',
            search: `?service-gallery=${title}`,
            state: {
                data: {
                    id,
                    title,
                    type: 'service',
                    header: 'Service'
                }
            }
        });
    };

    const getServiceData = (id, title) => {
        data= {id, title, header: 'Service'}
        localStorage.setItem('data', JSON.stringify(data));
    };
    return (
        <Fragment>
            <section className="proto-services section-padding mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-30"><span className="section-subtitle">What We Do</span>
                            <h2 className="section-title">Portfolio</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel
                                items={3}
                                margin={0}
                                dots={true}
                                smartSpeed={500}
                                nav
                                className="owl-carousel owl-theme"
                            >
                                {data?.map((ser, index) => (
                                    <div className="services-con" onClick={() => {getServiceData(ser.id, ser.name)}} key={index}>
                                        <div className="services-title">
                                            <h4>{ser.name}</h4>
                                        </div>
                                        <img src={`${mediaBaseUrl}${ser.cover_image}`} alt=""/>
                                        <a href={`/view-gallery?service=${ser.name}`}></a>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};
export default Service;
