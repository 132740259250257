import React, {Fragment, useState} from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';
import useStyles from '../../../components/modal/style';
import { DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const ResetPasswordEmailSent = ({handleClose, open,}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                classes={{ paper: classes.dialogPaper }}
                scroll="body"
            >
                <DialogTitle>
                    <div className='row'>
                        <div className='col-md-9 text-left'>
                            <h6>Reset password email sent</h6>
                        </div>
                        <div className='col-md-3 text-right'>
                     <span className='text-muted' style={{cursor: 'pointer'}}>
                        <Close onClick={handleClose} />
                        </span>
                        </div>
                    </div>

                </DialogTitle>
                <DialogContent dividers>
                    <form class="row">
                        <div className="col-md-12">
                            <label>
                                We have emailed you a password reset link. Kindly click the link to continue.
                            </label>
                        </div>
                        <div class="col-md-12 text-right">
                            <button
                                style={{width: '100%'}}
                                className="butn-dark mt-10"
                                type="button"
                                onClick={handleClose}
                            >
                                Got it!
                            </button>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default ResetPasswordEmailSent;
