import React, {Fragment, useEffect, useState} from "react";
import ViewGalleries from "./viewGallery";
import {useDispatch, useSelector} from "react-redux";
import {fetchClientPhotosRequest} from "../../redux/actions/clientPhotosActions";
import {fetchLoveStoryGalleryRequest} from "../../redux/actions/loveStoryGalleryActions";
import {fetchCategoryGalleryRequest} from "../../redux/actions/galleryActions";
import Loader from "../../components/loader/loader";
import {mediaBaseUrl} from "../../api/media_baseurl";
import PageHeader from "../../components/page-header/pageHeader";
import { Waypoint } from 'react-waypoint';
import {APP_NAME} from "../../config";

const ServicePage = (props) => {
    // const { id, title, type, header } = props.history.location.state.data;
    const galleryState = useSelector(state => state.GalleryReducer);
    const authState = useSelector(state => state.AuthReducer);
    const { loading, data } = galleryState;
    const [fetchGallery, setFetchGallery] = useState();
    const [serviceData, setServiceData] = useState({
        id: null,
        title: ''
    });
    const { user } = authState;
    const dispatch = useDispatch();

    useEffect(() => {
       const serviceData = JSON.parse(localStorage.getItem('data'))

         if (serviceData?.id) {
            setServiceData({
                ...serviceData,
                  id: serviceData.id,
                  title: serviceData.title
            });

         }
    }, [])


    useEffect(() => {
        if (serviceData?.id) {
            dispatch(
                fetchCategoryGalleryRequest({
                    id:serviceData.id})
            );
        }

    }, [dispatch, user.authToken,  serviceData.id]);
    return (
        <Fragment>
                    <header className="banner-header banner-img valign bg-img" data-overlay-dark="7"
                            data-stellar-background-ratio="0.5" style={{
                        backgroundImage: "url(" + `${mediaBaseUrl}${data?.p_image}` + ")"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 offset-md-5 text-right caption mt-60 animate-box" data-animate-effect="fadeInUp">
                                    {/*<h5>{'Service'}</h5>*/}
                                    <h1>{serviceData.title}</h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className="section-padding">
                        <div className="container">
                            <div className="row">
                                    <div className="col-md-8 mb-30 animate-box" data-animate-effect="fadeInUp">
                                        <p>{data?.page_image?.description}</p>
                                        <p>{data?.page_image?.description2}</p>
                                    </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-md-12 mb-30"><span className="section-subtitle">{serviceData.title}</span>*/}
                                    {/*        /!*<h4 style={{fontSize: 28}} className="section-title">{APP_NAME}</h4>*!/*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <ViewGalleries
                                        id={serviceData?.id}
                                        type={'service'}
                                        title={serviceData?.title}
                                        fetchGallery={fetchGallery}
                                        data={data?.gallery}
                                    />
                                </div>

                            </div>
                        </div>
                    </section>
        </Fragment>
    )
};
export default ServicePage;
