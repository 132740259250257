import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/loader/loader";
import ViewGalleries from "../service/viewGallery";
import {APP_NAME} from "../../config";
import {
    useParams
} from "react-router-dom";
import {fetchPubicGalelryRequest} from "../../redux/actions/publicGalleryActions";
import {setNotifyParams} from "../../redux/actions/notifications";
import PublicGalleryPasswordForm from "../../components/sharedGallery/publicGalleryPasswordForm";
const SharedGalleryDetails = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const publicGalleryState = useSelector(state => state.PublicGalleryReducer);
    const {loading, error, data } = publicGalleryState;
    const slug  = useParams();

    useEffect(() => {
            dispatch(
                fetchPubicGalelryRequest({
                    gallery: slug.gallery
                })
            );
    }, [dispatch, slug.gallery]);

    useEffect(() => {
        if (data?.password_protected) {
            handleOpen();
        }
    }, [data])

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Fragment>
            <>
                {loading ? <Loader/> : (
                    <>
                        <section className="section-padding mt-95">
                            <div className="container">
                                <div className="row jophoto-photos mt-15" id="jophoto-section-photos">
                                    <div className="col-md-12 animated fadeInUp">
                                        <div className="row">
                                            {data?.length > 0 &&
                                            <div className="col-md-12 mb-30"><span className="section-subtitle">{data?.client_gallery}</span>
                                                <h4 style={{fontSize: 28}} className="section-title">Gallery</h4>
                                            </div>
                                            }

                                        </div>
                                        <ViewGalleries
                                            token={slug.gallery}
                                            type={'public-detail'}
                                            title={'Gallery'}
                                            data={data?.gallery}
                                        />
                                    </div>

                                </div>
                            </div>
                        </section>
                    </>
                )}
            </>
            {
                open &&
                <PublicGalleryPasswordForm
                    open={open}
                    handleClose={handleClose}
                />
            }

        </Fragment>
    )
};
export default SharedGalleryDetails;