import { LOADER } from '../redux/actionTypes';

const initialState = {
  loading: false
};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case LOADER.FETCH_DATA:
      return {
        ...state,
        loading: action.payload.data
      };
    default:
      return state;
  }
}
