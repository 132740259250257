import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchCategoryGalleryApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/userportal/service-galleries/${data.id}/`)
    .then(res => res)
    .catch(err => err);
};

export const fetchClientGalleryGalleriesApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/userportal/client-gallery-galleries/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const fetchClientPhotosApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/userportal/client-photos/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};
