import React from 'react';
import Socials from '../socials/socials';

import 'react-chat-widget/lib/styles.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 footer-text-left">
                        <Socials/>
                    </div>
                    <div className="col-md-4 footer-text-center">
                        <div className="logo">
                            <h5><a href="/"><span>Ziri PHOTOGRAPHY</span></a></h5>
                        </div>
                    </div>
                    <div className="col-md-4 footer-text-right">
                        <span style={{marginTop: 30}}>&copy; {new Date().getFullYear()} <a href={'/'}><span>ZIRI</span></a>. All rights reserved</span>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </footer>
    );
};

export default Footer;
