import axios from 'axios';
const AxiosService = axios.create({
  baseURL: 'http://localhost:8001/'
});
export default AxiosService;
// class AxiosService {
// //   constructor() {
// //     const instance = axios.create();
// //     instance.interceptors.response.use(this.handleSuccess, this.handleError);
// //     this.instance = instance;
// //   }
// //
// //   handleSuccess(response) {
// //     return response;
// //   }
// //
// //   handleError(error) {
// //     return Promise.reject(error);
// //   }
// //
// //   get(url, header) {
// //     return this.instance.get(url, header);
// //   }
// //
// //   post(url, body, header) {
// //     return this.instance.post(url, body, header);
// //   }
// //
// //   patch(url, body, header) {
// //     return this.instance.patch(url, body, header);
// //   }
// //   put(url, body, header) {
// //     return this.instance.put(url, body, header);
// //   }
// //
// //   delete(url, header) {
// //     return this.instance.delete(url, header);
// //   }
// // }

// export default new AxiosService();
