import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/loader/loader";
import {mediaBaseUrl} from "../../api/media_baseurl";
import ViewGalleries from "../service/viewGallery";
import {fetchClientPhotosRequest} from "../../redux/actions/clientPhotosActions";
import {resetImages} from "../../redux/actions/resetImages";
import {fetchFavouriteGalleryDetailRequest} from "../../redux/actions/favouriteGalleryDetail";
import {APP_NAME} from "../../config";

const FavouriteDetails = (props) => {
    const galleryState = useSelector(state => state.FavouriteGalleryDetailReducer);
    const authState = useSelector(state => state.AuthReducer);
    const resetImageState = useSelector(state => state.ResetImagesReducer);
    const { loading, data } = galleryState;
    const [fetchGallery, setFetchGallery] = useState();
    const { user } = authState;
    const dispatch = useDispatch();
    const [favouriteData, setFavouriteData] = useState({
        id: null,
        title: '',
        description: {},
        cover_image: null
    });

    // useEffect(() => {
    //     if (resetImageState.resetImage) {
    //         dispatch(resetImages(false))
    //     }
    // }, [resetImageState.resetImage])

    useEffect(() => {
        const favouriteData = JSON.parse(localStorage.getItem('fav-data'))
        if (favouriteData?.id) {
            setFavouriteData({
                ...favouriteData,
                id: favouriteData.id,
                title: favouriteData.title,
            });

        }
    }, [])

    useEffect(() => {
        if (favouriteData?.id) {
            dispatch(
                fetchFavouriteGalleryDetailRequest({
                    id: favouriteData.id,
                    authToken: user.authToken
                })
            );
        }

    }, [dispatch, favouriteData.id]);
    return (
        <Fragment>
            <>
                {loading ? <Loader/> : (
                    <>
                        {/*<header className="banner-header banner-img valign bg-img" data-overlay-dark="7"*/}
                        {/*        data-stellar-background-ratio="0.5" style={{*/}
                        {/*    // backgroundImage: "url(" + `${mediaBaseUrl}${favouriteData?.cover_image}` + ")"}}>*/}
                        {/*    backgroundImage: "url(" + `img/home.jpeg` + ")"}}>*/}

                        {/*<div className="container">*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col-md-7 offset-md-5 text-right caption mt-60 animate-box"*/}
                        {/*                 data-animate-effect="fadeInUp">*/}
                        {/*                <h5>{favouriteData?.title} Favourite</h5>*/}
                        {/*                <h1>Gallery</h1>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</header>*/}
                        <section className="section-padding mt-95">
                            <div className="container">
                                <div className="row jophoto-photos mt-15" id="jophoto-section-photos">
                                    <div className="col-md-12 animated fadeInUp">
                                        <div className="row">
                                            <div className="col-md-12 mb-30"><span className="section-subtitle">{favouriteData?.title} Favourite</span>
                                                <h4 style={{fontSize: 28}} className="section-title">{APP_NAME}</h4>
                                            </div>
                                        </div>
                                        <ViewGalleries
                                            id={favouriteData?.id}
                                            favClientGallery={''}
                                            type={'favourite-detail'}
                                            title={favouriteData?.title}
                                            fetchGallery={fetchGallery}
                                            data={data?.galleries}
                                        />
                                    </div>

                                </div>
                            </div>
                        </section>
                    </>
                )}
            </>
        </Fragment>
    )
};
export default FavouriteDetails;