import React, {Fragment, useEffect} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const PageNotFound = () => {
    const authState = useSelector(state => state.AuthReducer);
    const {user} = authState;
    const history = useHistory();
    useEffect(() => {
            history.replace('/home')
    }, [])

    return (
        <Fragment></Fragment>
    )
}
export default PageNotFound;