import React, {Fragment, useState} from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import useStyles from '../../../components/modal/style';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {setNotifyParams} from "../../../redux/actions/notifications";
import {
    requestPasswordResetApi
} from "../../../api/authServices/authUser/loginUser";
import PopupNotif2 from "../../../components/notifications/popupnotif2";

const ResetPasswordEmail = ({
    handleClose,
    open,
    handleOpenMessage
    }) => {
    const [values, setStateValues] = useState({
        email: '',
    });
    const [processingRequest, setProcessingRequest] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });
    const resetError = () =>
        setNotifyPopUp({
            status: false,
            message: '',
            popupType: notifyPopUp.popupType
        });
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const dispatch = useDispatch();

    const resetForm = () => {
        reset({
            email: '',
        });
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }

    const requestPasswordReset = async () => {
        setProcessingRequest(true);
        const { email } = values;

        const data = {
            email
        };
        await requestPasswordResetApi({
            data,
            onSuccess: res => {
                setProcessingRequest(false);
                resetForm();
                // handleOpenConfirm();
                handleOpenMessage();
                handleClose();
            },
            onError: err => {
                setProcessingRequest(false);
                if (err.response) {
                    const { status } = err.response;
                    switch (status) {
                        case 400:
                            handleFavResValidationErrors(err.response.data);
                            break;
                        case 500:
                            dispatch(
                                setNotifyParams({
                                    status: true,
                                    message: 'Password reset request failed',
                                    type: 'error'
                                })
                            );
                            break;
                        default:
                            dispatch(setNotifyParams({
                                status: true,
                                message: 'Password reset request failed',
                                type: 'error'
                            }));
                    }
                }  else {
                    dispatch(setNotifyParams({
                        status: true,
                        message: 'Password reset request failed',
                        type: 'error'
                    }));
                }
            }
        });
    };

    const handleFavResValidationErrors = data => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('email')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.email[0],
                type: 'error'
            }));
        } else {
            dispatch(setNotifyParams({
                status: true,
                message: 'Failed to process request. Try again',
                type: 'error'
            }));
        }
    };

    const handleOnSubmit = async () => {
        await requestPasswordReset();
    };

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                classes={{ paper: classes.dialogPaper }}
                scroll="body"
            >
                <DialogTitle>
                    <div className='row'>
                        <div className='col-md-6 text-left'>
                            <h6>Password Reset</h6>
                        </div>
                        <div className='col-md-6 text-right'>
                     <span className='text-muted' style={{cursor: 'pointer'}}>
                        <Close onClick={handleClose} />
                        </span>
                        </div>
                    </div>

                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(handleOnSubmit)}  class="row">
                        <div className="col-md-12">
                            <label>
                                Please enter your email address
                            </label>
                        </div>
                        <div class="col-md-12">
                            <input
                                error={errors.token && true}
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Email is  required'
                                    },
                                })}
                                type="email"
                                onChange={e => handleChange(setStateValues, values, e)}
                                name="email" id="name" placeholder="Email" />
                            <div style={{ color: '#9C5450' }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    align="center">
                                    {errors.email && errors.email.message}
                                </Typography>
                            </div>
                        </div>

                        <div class="col-md-12 text-right">
                            <button
                                style={{width: '100%'}}
                                disabled={processingRequest}
                                className="butn-dark mt-10"
                                type="submit"
                            >
                                {processingRequest ?<CircularProgress size={12}
                                  style={{marginRight: 5,marginTop: 5, color: '#ffffff'}} /> : null}
                                Submit
                            </button>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                </DialogActions>
                <PopupNotif2
                    activate={notifyPopUp.status}
                    messageContent={notifyPopUp.message}
                    popupType={notifyPopUp.popupType}
                    resetError={() => resetError()}
                />
            </Dialog>
        </Fragment>
    );
};

export default ResetPasswordEmail;
