import { makeStyles } from "@material-ui/core";

const style = makeStyles((theme) => ({
  root: {
    borderRadius: "8px",
    padding: "6px 18px",
    height: "48px"
  }
}))


export default style;