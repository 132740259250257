import React, {Fragment, useEffect, useState} from 'react';
import {
    CircularProgress,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import useStyles from '../../../components/modal/style';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle } from '@material-ui/core';

import {setNotifyParams} from "../../../redux/actions/notifications";
import {
    resetPasswordApi
} from "../../../api/authServices/authUser/loginUser";
import PopupNotif2 from "../../../components/notifications/popupnotif2";
import routes from "../../../routeConstants";

const ResetPasswordForm = (props, {
       handleClose,
       open,
   }) => {
    const [values, setStateValues] = useState({
        password: '',
        password2: ''
    });
    const [processingRequest, setProcessingRequest] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });
    const resetError = () =>
        setNotifyPopUp({
            status: false,
            message: '',
            popupType: notifyPopUp.popupType
        });
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const dispatch = useDispatch();
    const history = useHistory();

    const resetForm = () => {
        reset({
            password: '',
            password2: ''
        });
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }

    const resetPassword = async () => {
        setProcessingRequest(true);
        const { password2, password } = values;
        const query = new URLSearchParams(history.location.search);

        const data = {
            password,
            password2,
            selector: query.get('selector'),
            validator: query.get('validator')
        };
        await resetPasswordApi({
            data,
            onSuccess: res => {
                setProcessingRequest(false);
                resetForm();
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: res.data.message,
                        type: 'success'
                    }));
                history.replace(routes.AUTH_LOGIN);
            },
            onError: err => {
                setProcessingRequest(false);
                if (err.response) {
                    const { status } = err.response;
                    switch (status) {
                        case 400:
                            handleFavResValidationErrors(err.response.data);
                            break;
                        case 500:
                            dispatch(
                                setNotifyParams({
                                    status: true,
                                    message: 'Password reset failed',
                                    type: 'error'
                                })
                            );
                            break;
                        default:
                            dispatch(setNotifyParams({
                                status: true,
                                message: 'Password reset failed',
                                type: 'error'
                            }));
                    }
                }
                // else {
                //     dispatch(setNotifyParams({
                //         status: true,
                //         message: 'Password reset failed',
                //         type: 'error'
                //     }));
                // }
            }
        });
    };

    const handleFavResValidationErrors = data => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('password')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.password[0],
                type: 'error'
            }));
        } else {
            dispatch(setNotifyParams({
                status: true,
                message: 'Failed to process request. Try again',
                type: 'error'
            }));
        }
    };

    const handleOnSubmit = async () => {
        await resetPassword();
    };

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };
    return (
        <Fragment>
                <DialogTitle>
                    <div className='row'>
                        <div className='col-md-6 text-left'>
                            <h6>Reset Your Password</h6>
                        </div>
                    </div>

                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(handleOnSubmit)}  className="row">
                        <div className="col-md-12">
                            <label>
                                Please enter your new password
                            </label>
                        </div>
                        <div className="col-md-12">
                            <input
                                error={errors.token && true}
                                {...register('password', {
                                    required: {
                                        value: true,
                                        message: 'New password is  required'
                                    },
                                })}
                                type="password"
                                onChange={e => handleChange(setStateValues, values, e)}
                                name="password" id="name" placeholder="Enter new password" />
                            <div style={{ color: '#9C5450' }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    align="center">
                                    {errors.password && errors.password.message}
                                </Typography>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <input
                                error={errors.token && true}
                                {...register('password2', {
                                    required: {
                                        value: true,
                                        message: 'Confirm your password'
                                    },
                                })}
                                type="password"
                                onChange={e => handleChange(setStateValues, values, e)}
                                name="password2" id="name" placeholder="Confirm password"/>
                            <div style={{color: '#9C5450'}}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    align="center">
                                    {errors.password2 && errors.password2.message}
                                </Typography>
                            </div>
                        </div>

                        <div className="col-md-12 text-right">
                            <button
                                style={{width: '100%'}}
                                disabled={processingRequest}
                                className="butn-dark mt-10"
                                type="submit"
                            >
                                {processingRequest ?<CircularProgress size={12}
                                 style={{marginRight: 5,marginTop: 5, color: '#ffffff'}} /> : null}
                                Reset Password
                            </button>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                </DialogActions>
                <PopupNotif2
                    activate={notifyPopUp.status}
                    messageContent={notifyPopUp.message}
                    popupType={notifyPopUp.popupType}
                    resetError={() => resetError()}
                />
            {/*<ConfirmPasswordResetMessage*/}
            {/*    open={openConfirm}*/}
            {/*    handleClose={handleCloseConfirm}*/}
            {/*/>*/}
        </Fragment>
    );
};

export default ResetPasswordForm;
