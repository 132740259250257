import {recoverAuth} from "../redux/actions/authActions";
import { setNotifyParams } from "../redux/actions/notifications";

export const recoverUserAuth = dispatch => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        dispatch(recoverAuth(user));
    }
};

export const initRequest = (dispatch, request) => {
    const user = JSON.parse(localStorage.getItem('user'));
    dispatch(recoverAuth(user));
    dispatch(
        request({
            id: user.userDetail.id,
            authToken: user.authToken
        })
    );
};

export const dispatchNotifyActions = (dispatch, type, message) => {
    dispatch(
      setNotifyParams({
        status: true,
        type: type,
        message: message
      })
    );
  };
  