import { FAVOURITE_GALLERY } from '../actionTypes';
import { dataFetching } from './loaderAction';
import {fetchUserFavouriteGalleriesApi
} from '../../api/authServices/favourite/favourite';

export const fetchFavouriteGallery = () => ({
    type: FAVOURITE_GALLERY.FETCH_FAVOURITE_GALLERY_DATA
});

export const fetchFavouriteGallerySuccess = data => ({
    type: FAVOURITE_GALLERY.FETCH_FAVOURITE_GALLERY_SUCCESS,
    payload: {
        data
    }
});

export const fetchFavouriteGalleryFail = err => ({
    type: FAVOURITE_GALLERY.FETCH_FAVOURITE_GALLERY_FAIL,
    payload: {
        err
    }
});

export const fetchFavouriteGalleryRequest = data => {
    return dispatch => {
        dispatch(dataFetching(true))
        dispatch(fetchFavouriteGallery());
        fetchUserFavouriteGalleriesApi(data)
            .then(res => {
                dispatch(fetchFavouriteGallerySuccess(res.data));
                dispatch(dataFetching(false))
            })
            .catch(err => {
                dispatch(fetchFavouriteGalleryFail(err.message));
                dispatch(dataFetching(false))
            });
    };
};
