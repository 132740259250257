import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchLoveStoryApi = async data => {
  return await AxiosService.get(`${baseUrl}/userportal/love-stories/`)
    .then(res => res)
    .catch(err => err);
};

export const fetchLoveStoryGalleryApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/userportal/galleries/love-stories/${data.id}/`)
    .then(res => res)
    .catch(err => err);
};

