import React, {Fragment, useEffect, useState} from 'react';
import PageHeader from "../../components/page-header/pageHeader";
import {useDispatch, useSelector} from "react-redux";
import {fetchContactUsRequest} from "../../redux/actions/contactUsActions";
import Loader from "../../components/loader/loader";
import {useHistory} from "react-router-dom";
import PopupNotif2 from "../../components/notifications/popupnotif2";
import {useForm} from "react-hook-form";
import {authUserRequest} from "../../redux/actions/authActions";
import {Button, CircularProgress, Typography} from "@material-ui/core";
import {AddCircle} from "@material-ui/icons";
import {changePasswordApi} from "../../api/authServices/authUser/loginUser";
import {setNotifyParams} from "../../redux/actions/notifications";
import ConfirmPasswordChangeToken from "./modal/confirmPasswordChangeToken";

const ChangePasswordPage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;

    const [values, setStateValues] = useState({
        oldPassword: '',
        confirmPassword: '',
        newPassword: ''
    });
    const [authenticatingUser, setAuthenticatingUser] = useState(false);
    const [open, setOpen] = useState(false);
    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });
    const resetError = () =>
        setNotifyPopUp({
            status: false,
            message: '',
            popupType: notifyPopUp.popupType
        });

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const resetInitForm = () => {
        reset({
            newPassword: '',
            oldPassword: '',
            confirmPassword: ''
        });
    };

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };



    const handleOnSubmit = async (values, id, authToken) => {
        setAuthenticatingUser(true);
        const { oldPassword, confirmPassword, newPassword } = values;
          await  changePasswordApi({
                data: {
                    id: user.userDetail.id,
                    authToken: user.authToken,
                    oldPassword ,
                    newPassword,
                    confirmPassword
                },
                onSuccess: res => {
                    setAuthenticatingUser(false);
                     handleOpen();
                    // resetForm();
                },
                onError: err => {
                    setAuthenticatingUser(false);
                    if (err.response) {
                        const { data, status } = err.response;
                        switch (status) {
                            case 400:
                                handleFavResValidationErrors(data);
                                break;
                            case 500:
                                setNotifyPopUp({
                                    status: true,
                                    popupType: 'error',
                                    message: 'Password change failed. please try again'
                                });
                                break;
                            default:
                                setNotifyPopUp({
                                    status: true,
                                    popupType: 'error',
                                    message: 'Password change failed. please try again'
                                });
                        }
                    } else {
                        setNotifyPopUp({
                            status: true,
                            popupType: 'error',
                            message: 'Password change failed. please try again'
                        });
                    }

                }
            });
    };

    const handleFavResValidationErrors = data => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('old_password')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.old_password[0],
                type: 'error'
            }));
        } else if (data.hasOwnProperty('password')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.password[0],
                type: 'error'
            }));
        } else if (data.hasOwnProperty('password2')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.password[0],
                type: 'error'
            }));
        }
        else {
            dispatch(setNotifyParams({
                status: true,
                message: 'Failed to process request. Try again',
                type: 'error'
            }));
        }
    };
    return (
        <Fragment>
            <section className="section-padding mt-100">
                <div className="container">
                    <div className="row mb-90">
                        <div className="col-md-3 mb-30 animate-box" data-animate-effect="fadeInUp">

                        </div>
                        <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                            <h6>Change your password</h6>
                            <form  onSubmit={handleSubmit(handleOnSubmit)} className="row">
                                <div className="col-md-12">
                                    <input
                                        error={errors.oldPassword && true}
                                        {...register('oldPassword', {
                                            required: {
                                                value: true,
                                                message: 'Old password is required'
                                            }
                                        })}
                                        type="password"
                                        name="oldPassword"
                                        id="oldPassword"
                                        placeholder="Old password"
                                        onChange={e =>
                                            handleChange(setStateValues, values, e)
                                        }
                                    />
                                    <div style={{ color: '#9C5450' }}>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            align="center">
                                            {errors.oldPassword && errors.oldPassword.message}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <input
                                        error={errors.newPassword && true}
                                        {...register('newPassword', {
                                            required: {
                                                value: true,
                                                message: 'New password is required'
                                            }
                                        })}
                                        onChange={e =>
                                            handleChange(setStateValues, values, e)
                                        }
                                        type="password"
                                        name="newPassword"
                                        id="newPassword"
                                        placeholder="New password"
                                    />
                                    <div style={{ color: '#9C5450' }}>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            align="center">
                                            {errors.newPassword && errors.newPassword.message}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <input
                                        error={errors.confirmPassword && true}
                                        {...register('confirmPassword', {
                                            required: {
                                                value: true,
                                                message: 'Confirm new password'
                                            },
                                        })}
                                        onChange={e =>
                                            handleChange(setStateValues, values, e)
                                        }
                                        type="password"
                                        name="confirmPassword"
                                        id="newPassword"
                                        placeholder="Confirm new password"
                                    />
                                    <div style={{ color: '#9C5450' }}>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            align="center">
                                            {errors.confirmPassword && errors.confirmPassword.message}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button
                                        style={{width: '100%'}}
                                        disabled={authenticatingUser}
                                        className="butn-dark mt-10" type="submit">
                                        {authenticatingUser ?<CircularProgress size={12} style={{marginRight: 5,marginTop: 5, color: '#ffffff'}} /> : null}
                                        Change Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {open &&
            (
                <ConfirmPasswordChangeToken
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    setOpen={setOpen}
                    open={open}
                    securityDetails={values}
                    resetInitForm={resetInitForm}
                    resendCode={handleOnSubmit}
                />
            )
            }

            <PopupNotif2
                activate={notifyPopUp.status}
                messageContent={notifyPopUp.message}
                popupType={notifyPopUp.popupType}
                resetError={() => resetError()}
            />

        </Fragment>
    )
};
export default ChangePasswordPage;