import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {initRequest} from "../../utils/helper";
import {fetchClientGalleryRequest} from "../../redux/actions/clientGalleryActions";
import {mediaBaseUrl} from "../../api/media_baseurl";
import {resetImages} from "../../redux/actions/resetImages";
import {fetchClientPhotosRequest} from "../../redux/actions/clientPhotosActions";
import Loader from "../../components/loader/loader";
import Slideshow from "../../components/slideShow/slideImage";
import {Grid} from "@material-ui/core";
import ViewGalleries from "../service/viewGallery";
import {fetchFavouriteGalleryRequest} from "../../redux/actions/favouriteGalleryActions";
import {APP_NAME} from "../../config";

const Favourites = () => {
    const favouriteState = useSelector(state => state.FavouriteGalleryReducer);
    const authState = useSelector(state => state.AuthReducer);
    const resetImageState = useSelector(state => state.ResetImagesReducer);
    const loaderState  = useSelector(state => state.LoaderReducer);
    const { loading, data } = favouriteState;
    const [fetchGallery, setFetchGallery] = useState();
    const { user } = authState;
    const dispatch = useDispatch();
    const [serviceData, setServiceData] = useState({
        id: null,
        title: '',
        description: {},
        cover_image: null
    });

    // useEffect(() => {
    //     if (resetImageState.resetImage) {
    //         dispatch(resetImages(false))
    //     }
    // }, [resetImageState.resetImage])

    // useEffect(() => {
    //     const serviceData = JSON.parse(localStorage.getItem('data'))
    //     if (serviceData?.id) {
    //         setServiceData({
    //             ...serviceData,
    //             id: serviceData.id,
    //             title: serviceData.title,
    //             cover_image: serviceData.image
    //         });
    //
    //     }
    // }, [])

    useEffect(() => {
        initRequest(dispatch, fetchFavouriteGalleryRequest)
    }, []);
    return (
        <Fragment>
                        {/*<header className="banner-header banner-img valign bg-img" data-overlay-dark="7"*/}
                        {/*        data-stellar-background-ratio="0.5" style={{*/}
                        {/*    // backgroundImage: "url(" + `${mediaBaseUrl}${serviceData.cover_image}` + ")"}}>*/}
                        {/*    backgroundImage: "url(" + `img/home4.jpeg` + ")"}}>*/}

                        {/*    <div className="container">*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col-md-7 offset-md-5 text-right caption mt-60 animated fadeInUp">*/}
                        {/*                <h5>{'Your'}</h5>*/}
                        {/*                <h1>{'Favourites'}</h1>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</header>*/}
                        <section className="section-padding mt-95">
                            <div className="container">
                                <div className="row jophoto-photos mt-15" id="jophoto-section-photos">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 mb-30"><span className="section-subtitle">{'Your Favourites'}</span>
                                                <h4 style={{fontSize: 28}} className="section-title">{APP_NAME}</h4>
                                            </div>
                                        </div>
                                        {data?.length > 0 ?
                                        <ViewGalleries
                                            // id={serviceData?.id}
                                            type={'favourite'}
                                            title={'Favourites'}
                                            fetchGallery={fetchGallery}
                                            data={data}
                                        /> :

                                            (
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} md={12} style={{height: 400, marginTop: 90}}>
                                                        <h4 className={'align-middle text-center text-black-50'}>
                                                            No favourite found
                                                        </h4>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                    </div>

                                </div>
                            </div>
                        </section>
        </Fragment>
    )
}
export default Favourites;