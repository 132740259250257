import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchSharedLinkApi = async ({ data, onSuccess, onError }) => {
    return await AxiosService.get(`${baseUrl}/photogallery/${data.link}/`, {
        headers: { Authorization: `${data.authToken}` }
    })
        .then(res => res)
        .catch(err => err);
};

export const generateGalleryLinkApi = async ({ data, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('password', data.password);
    formData.append('clientgallery', data.clientGallery);
    formData.append('gallery', JSON.stringify(data.gallery));
    return await AxiosService.post(
        `${baseUrl}/photogallery/generate-gallery-link/`,
        formData,
        { headers: { Authorization: `${data.authToken}` } }
    )
        .then(res => onSuccess && onSuccess(res))
        .catch(err => onError && onError(err));
};


export const updateGalleryLinkApi = async ({ data, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('password', data.password);

    return await AxiosService.post(
        `${baseUrl}/userportal/create-favourite-galleries/`,
        data,
        { headers: { Authorization: `${data.authToken}` } }
    )
        .then(res => onSuccess && onSuccess(res))
        .catch(err => onError && onError(err));
};

export const deleteGalleryLinkApi =  ({ data, onSuccess, onError }) => {
    return  AxiosService.delete(
        `${baseUrl}/userportal/favourites/${data.id}/`,
        {
            headers: { Authorization: `${data.authToken}` }
        }
    )
        .then(res => onSuccess && onSuccess(res))
        .catch(err => onError && onError(err));
};
