export const AUTH = {
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  SET_MAX_TIME_OUT: 'SET_MAX_TIME_OUT',
  IS_USER_LOGGED_IN: 'IS_USER_LOGGED_IN',
  AUTH_USER: 'AUTH_USER',
  AUTH_USER_SUCCESS: 'AUTH_USER_SUCCESS',
  AUTH_USER_FAIL: 'AUTH_USER_FAIL',
  LOGOUT_USER: 'LOGOUT_USER',
  RECOVER_AUTH: 'RECOVER_AUTH',
  FETCH_UPDATED_USER: 'FETCH_UPDATED_USER',
  STORE_LOGIN_USER: 'STORE_LOGIN_USER'
};
export const SUCCESS = {
  SET_MESSAGE: 'SET_MESSAGE'
};

export const SEARCH = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL'
};

export const GALLERY_LINKS = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL'
};

export const USERS = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAIL: 'FETCH_DATA_FAIL'
};

export const ADMIN = {
  FETCH_ADMIN_DATA: 'FETCH_FETCH_ADMIN_DATADATA',
  FETCH_ADMIN_DATA_SUCCESS: 'FETCH_ADMIN_DATA_SUCCESS',
  FETCH_ADMIN_DATA_FAIL: 'FETCH_ADMIN_DATA_FAIL'
};

export const CATEGORY = {
  FETCH_CATEGORY_DATA: 'FETCH_CATEGORY_DATA',
  FETCH_CATEGORY_SUCCESS: 'FETCH__CATEGORY_DATA_SUCCESS',
  FETCH_CATEGORY_FAIL: 'FETCH_CATEGORY_DATA_FAIL'
};

export const GALLERY = {
  FETCH_GALLERY_DATA: 'FETCH_GALLERY_DATA',
  FETCH_GALLERY_SUCCESS: 'FETCH_GALLERY_DATA_SUCCESS',
  FETCH_GALLERY_FAIL: 'FETCH_GALLERY_DATA_FAIL',
};

export const LOVE_STORY = {
  FETCH_LOVE_STORY_DATA: 'FETCH_LOVE_STORY_DATA',
  FETCH_LOVE_STORY_SUCCESS: 'FETCH_LOVE_STORY_SUCCESS',
  FETCH_LOVE_STORY_FAIL: 'FETCH_LOVE_STORY_FAIL'
};

export const LOVE_STORY_GALLERY = {
  FETCH_LOVE_STORY_GALLERY_DATA: 'FETCH_LOVE_STORY_GALLERY_DATA',
  FETCH_LOVE_STORY_GALLERY_SUCCESS: 'FETCH_LOVE_STORY_GALLERY_SUCCESS',
  FETCH_LOVE_STORY_GALLERY_FAIL: 'FETCH_LOVE_STORY_GALLERY_FAIL'
};

export const CLIENT_GALLERY = {
  FETCH_CLIENT_GALLERY_DATA: 'FETCH_CLIENT_GALLERY_DATA',
  FETCH_CLIENT_GALLERY_SUCCESS: 'FETCH_CLIENT_GALLERY_SUCCESS',
  FETCH_CLIENT_GALLERY_FAIL: 'FETCH_CLIENT_GALLERY_FAIL'
};

export const CATEGORY_GALLERY = {
  FETCH_CATEGORY_GALLERY_DATA: 'FETCH_CATEGORY_GALLERY_DATA',
  FETCH_CATEGORY_GALLERY_SUCCESS: 'FETCH_CATEGORY_GALLERY_SUCCESS',
  FETCH_CATEGORY_GALLERY_FAIL: 'FETCH_CATEGORY_GALLERY_FAIL'
};

export const THE_TEAM = {
  FETCH_TEAM_DATA: 'FETCH_TEAM_DATA',
  FETCH_TEAM_SUCCESS: 'FETCH_TEAM_SUCCESS',
  FETCH_TEAM_FAIL: 'FETCH_TEAM_FAIL'
};

export const ABOUT_PAGE = {
  FETCH_ABOUT_DATA: 'FETCH_ABOUT_DATA',
  FETCH_ABOUT_SUCCESS: 'FETCH_ABOUT_SUCCESS',
  FETCH_ABOUT_FAIL: 'FETCH_ABOUT_FAIL'
};

export const CONTACT_US = {
  FETCH_CONTACT_DATA: 'FETCH_CONTACT_DATA',
  FETCH_CONTACT_SUCCESS: 'FETCH_CONTACT_SUCCESS',
  FETCH_CONTACT_FAIL: 'FETCH_CONTACT_FAIL'
};

export const SERVICE_PAGE = {
  FETCH_SERVICE_PAGE_DATA: 'FETCH_SERVICE_PAGE_DATA',
  FETCH_SERVICE_PAGE_SUCCESS: 'FETCH_SERVICE_SUCCESS',
  FETCH_SERVICE_PAGE_FAIL: 'FETCH_SERVICE_FAIL'
};

export const USER_ENQUERIES = {
  FETCH_ENQUERIES_DATA: 'FETCH_ENQUERIES_PAGE_DATA',
  FETCH_ENQUERIES_SUCCESS: 'FETCH_ENQUERIES_SUCCESS',
  FETCH_ENQUERIES_FAIL: 'FETCH_ENQUERIES_FAIL'
};

export const PAGE_IMAGES = {
  FETCH_PAGE_IMAGES_DATA: 'FETCH_PAGE_IMAGES_DATA',
  FETCH_PAGE_IMAGES_SUCCESS: 'FETCH_PAGE_IMAGES_SUCCESS',
  FETCH_PAGE_IMAGES_FAIL: 'FETCH_PAGE_IMAGES_FAIL'
};

export const DASHBOARD = {
  FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA',
  FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',
  FETCH_DASHBOARD_FAIL: 'FETCH_DASHBOARD_FAIL'
};

export const CLIENT_PHOTOS = {
  FETCH_CLIENT_PHOTO_DATA: 'FETCH_CLIENT_PHOTO_DATA',
  FETCH_CLIENT_PHOTO_SUCCESS: 'FETCH_CLIENT_PHOTO_SUCCESS',
  FETCH_CLIENT_PHOTO_FAIL: 'FETCH_CLIENT_PHOTO_FAIL'
};

export const FILE_UPLOAD = {
  SET_UPLOADED_IMAGE: 'SET_UPLOADED_IMAGE',
  RESET_UPLOADED_IMAGE: 'RESET_UPLOADED_IMAGE',
  IMAGE_UPLOADED: 'IMAGE_UPLOADED',
  SET_CROP_IMAGE_DATA: 'SET_CROP_IMAGE_DATA'
};

export const NOTIFICATIONS = {
  SET_STATUS_AND_TYPE: 'SET_STATUS_AND_TYPE'
};
export const CAROUSEL = {
  FETCH_CAROUSEL_DATA: 'FETCH_CAROUSEL_DATA',
  FETCH_CAROUSEL_SUCCESS: 'FETCH_CAROUSEL_SUCCESS',
  FETCH_CAROUSEL_FAIL: 'FETCH_CAROUSEL_FAIL'
};
export const ABOUT = {
  FETCH_ABOUT_DATA: 'FETCH_ABOUT_DATA',
  FETCH_ABOUT_SUCCESS: 'FETCH_ABOUT_SUCCESS',
  FETCH_ABOUT_FAIL: 'FETCH_ABOUT_FAIL'
};
export const CONTACT_US_INFO = {
  FETCH_CONTACT_INFO_DATA: 'FETCH_CONTACT_INFO_DATA',
  FETCH_CONTACT_INFO_SUCCESS: 'FETCH_CONTACT_INFO_SUCCESS',
  FETCH_CONTACT_INFO_FAIL: 'FETCH_CONTACT_INFO_FAIL'
};
export const LOADER = {
  FETCH_DATA: 'FETCH_DATA'
};
export const FAVOURITE = {
  FETCH_FAVOURITE_DATA: 'FETCH_FAVOURITE_DATA',
  FETCH_FAVOURITE_SUCCESS: 'FETCH_FAVOURITE_SUCCESS',
  FETCH_FAVOURITE_FAIL: 'FETCH_FAVOURITE_FAIL'
};
export const RESET_IMAGES = {
  FETCH_RESET_IMAGES: 'FETCH_RESET_IMAGES'
};
export const FAVOURITE_GALLERY = {
  FETCH_FAVOURITE_GALLERY_DATA: 'FETCH_FAVOURITE_GALLERY_DATA',
  FETCH_FAVOURITE_GALLERY_SUCCESS: 'FETCH_FAVOURITE_GALLERY_SUCCESS',
  FETCH_FAVOURITE_GALLERY_FAIL: 'FETCH_FAVOURITE_GALLERY_FAIL'};

export const REFRESH_GALLERY = {
  REFRESH_PHOTO_GALLERY: 'REFRESH_PHOTO_GALLERY'
};

export const FAVOURITE_GALLERY_DETAIL = {
  FETCH_FAVOURITE_GALLERY_DETAIL_DATA: 'FETCH_FAVOURITE_GALLERY_DETAIL_DATA',
  FETCH_FAVOURITE_GALLERY_DETAIL_SUCCESS: 'FETCH_FAVOURITE_GALLERY_DETAIL_SUCCESS',
  FETCH_FAVOURITE_GALLERY_DETAIL_FAIL: 'FETCH_FAVOURITE_GALLERY_DETAIL_FAIL'};

export const FAVOURITE_PHOTO_STATUSES = {
  FETCH_FAVOURITE_PHOTO_STATUSES_DATA: 'FETCH_FAVOURITE_PHOTO_STATUSES_DATA',
  FETCH_FAVOURITE_PHOTO_STATUSES_SUCCESS: 'FETCH_FAVOURITE_PHOTO_STATUSES_SUCCESS',
  FETCH_FAVOURITE_PHOTO_STATUSES_FAIL: 'FETCH_FAVOURITE_PHOTO_STATUSES_FAIL'};

export const PUBLIC_GALLERY = {
  FETCH_PUBLIC_GALLERY_DATA: 'FETCH_PUBLIC_GALLERY_DATA',
  FETCH_PUBLIC_GALLERY_SUCCESS: 'FETCH_PUBLIC_GALLERY_SUCCESS',
  FETCH_PUBLIC_GALLERY_FAIL: 'FETCH_PUBLIC_GALLERY_FAIL'
};