import React, {Fragment} from 'react';

const LeftPanel = () => {
    return (
        <Fragment>
            <div className="left-panel">
                <div className="left-txt">
                    <span>-</span> <a href="https://www.facebook.com/ziriphoto" target="_blank">Facebook</a>
                    <span>-</span> <a href="https://twitter.com/ziriphotography" target="_blank">
                    Twitter</a> <span>-</span>
                    <a href="https://www.instagram.com/ziriphotography/" target="_blank">Instagram</a>
                </div>
            </div>
        </Fragment>
    );
};
export default LeftPanel;
