import { RESET_IMAGES } from '../redux/actionTypes';

const initialState = {
    resetImage: false
};

export default function resetImageReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_IMAGES.FETCH_RESET_IMAGES:
            return {
                ...state,
               resetImage: action.payload.data
            };
        default:
            return state;
    }
}
