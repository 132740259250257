import React, {Fragment} from 'react';
import OwlCarousel from "react-owl-carousel";
import {mediaBaseUrl} from "../../api/media_baseurl";

const TheTeam = ({ data }) => {
    return (
        <Fragment>
            <section className="team section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-30">
                            {/* <span className="section-subtitle">Senior</span> */}
                            <h2 className="section-title">Our Team</h2>
                        </div>
                    </div>
                    <div className="row">
                        <OwlCarousel
                            items={1}
                            loop
                            mouseDrag={true}
                            data={true}
                            autoplay={false}
                            margin={30}
                            dots={false}
                            responsiveClass={true}
                            nav={false}
                            responsive={{0: {items: 1, }, 600: {items: 2}, 1000: {items: 3}}}
                            className="col-md-12 owl-carousel owl-theme animate-box" data-animate-effect="fadeInUp"
                        >
                            {data?.map((team) => (
                                <div className="item">
                                    <div className="img"><img src={`${mediaBaseUrl}${team.cover_image}`} alt=""  style={{pointerEvents: 'none'}}/></div>
                                    <div className="info">
                                        <h6>{team.name}</h6>
                                        <p>{team.title}</p>
                                        <div className="social valign">
                                            <div className="full-width">
                                                {/*<p>{team.email}</p>*/}
                                                <p>{team.name}</p>
                                                <a href="#"><i className="ti-facebook"></i></a> <a
                                                href="#"><i className="ti-twitter-alt"></i></a> <a href="#"><i
                                                className="ti-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};
export default TheTeam;
