import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchAllClientGalleryApi = async data => {
  return await AxiosService.get(
    `${baseUrl}/adminportal/manage-client-galleries/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => res)
    .catch(err => err);
};

export const fetchClientGalleryApi = async (data) => {
  return await AxiosService.get(`${baseUrl}/userportal/client-galleries/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
 )
    .then(res => res)
    .catch(err => err);
};

