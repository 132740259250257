import React, {Fragment} from 'react';
import Img from "react-cool-img";
import {mediaBaseUrl} from "../../../api/media_baseurl";
import {APP_NAME} from "../../../config";
const AboutUs = ({ data,status }) => {
    return (
        <Fragment>
            <section className="proto-services section-padding">
                <div className="container">
                    {status && (
                        <div className="row">
                            <div className="col-md-12 mb-30"><span className="section-subtitle">About Us</span>
                                <h4 style={{fontSize: 28}} className="section-title">{APP_NAME}</h4>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-3 animate-box" data-animate-effect="fadeInUp"><span
                                    className="line-one"></span></div>
                                <div className="col-md-8 animate-box mb-60" data-animate-effect="fadeInUp">
                                    <h6>{data?.header_text}</h6>
                                    <p>{data?.info_text1}</p>
                                    <p>{data?.info_text2}</p>
                                    {/*<img src={`${mediaBaseUrl}${data?.info_image}`} className="sign" alt=""  />*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 animate-box" data-animate-effect="fadeInUp">
                            <div className="jophoto-about-img">
                                <div className="img"><img src={`${mediaBaseUrl}${data?.info_image}`} style={{width: '100%', height: 'auto'}} alt="" /></div></div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default AboutUs;
