import { FAVOURITE_GALLERY_DETAIL } from '../redux/actionTypes';

const initialState = {
    loading: false,
    data: [],
    error: false
};

export default function favouriteGalleryDetailReducer(state = initialState, action) {
    switch (action.type) {
        case FAVOURITE_GALLERY_DETAIL.FETCH_FAVOURITE_GALLERY_DETAIL_DATA:
            return {
                ...state,
                loading: true,
                data: [],
                error: false
            };
        case FAVOURITE_GALLERY_DETAIL.FETCH_FAVOURITE_GALLERY_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data
            };
        case FAVOURITE_GALLERY_DETAIL.FETCH_FAVOURITE_GALLERY_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
}
