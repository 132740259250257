import { NOTIFICATIONS } from '../redux/actionTypes';

const initialState = {
  status: false,
  type: 'error',
  message: ''
};

export default function notifyReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS.SET_STATUS_AND_TYPE:
      return {
        ...state,
        status: action.payload.data.status,
        type: action.payload.data.type,
        message: action.payload.data.message
      };
    default:
      return state;
  }
}
