import React, {Fragment, useState} from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import useStyles from '../../../components/modal/style';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {setNotifyParams} from "../../../redux/actions/notifications";
import {changePasswordApi, confirmChangePasswordApi} from "../../../api/authServices/authUser/loginUser";
import PopupNotif2 from "../../../components/notifications/popupnotif2";

const ConfirmPasswordChangeToken = ({
  handleClose,
  open,
  securityDetails,
  resetInitForm,
  resendCode
}) => {
    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;
    const [values, setStateValues] = useState({
        token: '',
    });
    const [processingRequest, setProcessingRequest] = useState(false);
    const [authenticatingUser, setAuthenticatingUser] = useState(false);
    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });
    const resetError = () =>
        setNotifyPopUp({
            status: false,
            message: '',
            popupType: notifyPopUp.popupType
        });
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const dispatch = useDispatch();

    const resetForm = () => {
        reset({
            code: '',
        });
    };

    const confirmProcessPasswordChange = async () => {
        setProcessingRequest(true);
        const { oldPassword, confirmPassword, newPassword } = securityDetails;

        const data = {
            token: values.token,
            oldPassword,
            confirmPassword,
            newPassword,
            authToken: user.authToken,
            id: user.userDetail.id
        };
        await confirmChangePasswordApi({
            data,
            onSuccess: res => {
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Password successfully changed',
                        type: 'success'
                    })
                );
                setProcessingRequest(false);
                resetForm();
                resetInitForm();
                handleClose();
            },
            onError: err => {
                setProcessingRequest(false);
                if (err.response) {
                    const { status } = err.response;
                    switch (status) {
                        case 400:
                            handleFavResValidationErrors(err.response.data);
                            break;
                        case 500:
                            dispatch(
                                setNotifyParams({
                                    status: true,
                                    message: 'Password changed failed',
                                    type: 'error'
                                })
                            );
                            break;
                        default:
                            dispatch(setNotifyParams({
                                status: true,
                                message: 'Password changed failed',
                                type: 'error'
                            }));
                    }
                }  else {
                    dispatch(setNotifyParams({
                        status: true,
                        message: 'Password changed failed',
                        type: 'error'
                    }));
                }

            }
        });
    };

    const handleFavResValidationErrors = data => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('security_code')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.security_code[0],
                type: 'error'
            }));
        } else if (data.hasOwnProperty('old_password')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.old_password[0],
                type: 'error'
            }));
        } else if (data.hasOwnProperty('password')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.password[0],
                type: 'error'
            }));
        } else if (data.hasOwnProperty('password2')) {
            dispatch(setNotifyParams({
                status: true,
                message: data.password[0],
                type: 'error'
            }));
        }
        else {
            dispatch(setNotifyParams({
                status: true,
                message: 'Failed to process request. Try again',
                type: 'error'
            }));
        }
    };

    const handleResendCode = async () => {
        setAuthenticatingUser(true);
        const { oldPassword, confirmPassword, newPassword } = securityDetails;
        await  changePasswordApi({
            data: {
                id: user.userDetail.id,
                authToken: user.authToken,
                oldPassword ,
                newPassword,
                confirmPassword
            },
            onSuccess: res => {
                setAuthenticatingUser(false);
                dispatch(
                    setNotifyParams({
                        status: true,
                        message: 'Code successfully sent to your email',
                        type: 'success'
                    })
                );
            },
            onError: err => {
                setAuthenticatingUser(false);
                if (err.response) {
                    const { data, status } = err.response;
                    switch (status) {
                        case 400:
                            handleFavResValidationErrors(data);
                            break;
                        case 500:
                            setNotifyPopUp({
                                status: true,
                                popupType: 'error',
                                message: 'Password change failed. please try again'
                            });
                            break;
                        default:
                            setNotifyPopUp({
                                status: true,
                                popupType: 'error',
                                message: 'Password change failed. please try again'
                            });
                    }
                } else {
                    setNotifyPopUp({
                        status: true,
                        popupType: 'error',
                        message: 'Password change failed. please try again'
                    });
                }

            }
        });
    };

    const handleOnSubmit = async () => {
        await confirmProcessPasswordChange();
    };

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                classes={{ paper: classes.dialogPaper }}
                scroll="body"

            >
                <DialogTitle>
                    <div className='row'>
                        <div className='col-md-6 text-left'>
                            <h6>Password Change</h6>
                        </div>
                        <div className='col-md-6 text-right'>
                     <span className='text-muted' style={{cursor: 'pointer'}}>
                        <Close onClick={handleClose} />
                        </span>
                        </div>
                    </div>

                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(handleOnSubmit)}  class="row">
                        <div className="col-md-12">
                            <label>
                                Please enter the six digit code sent your email
                            </label>
                        </div>
                        <div class="col-md-12">
                            <input
                             error={errors.token && true}
                             {...register('token', {
                                 required: {
                                     value: true,
                                     message: 'Six digit code is required'
                                 },
                             })}
                            type="text"
                            onChange={e => handleChange(setStateValues, values, e)}
                            name="token" id="name" placeholder="Enter code" />
                            <div style={{ color: '#9C5450' }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    align="center">
                                    {errors.token && errors.token.message}
                                </Typography>
                            </div>
                            <div className="col-md-12">
                                <label>

                                    <>
                                    Did not receive code?
                                        {!authenticatingUser &&
                                        <a href={'javascript: void();'}
                                           type='button'
                                           onClick={handleResendCode}
                                           style={{color: '#9C5450'}}>
                                            Resend
                                        </a>
                                        }
                                    </>
                                    {authenticatingUser ?<CircularProgress size={10} style={{marginLeft: 5,color: 'black'}} /> : null}
                                </label>
                            </div>
                             </div>

                        <div class="col-md-12 text-right">
                            <button
                                style={{width: '100%'}}
                                disabled={processingRequest}
                                className="butn-dark mt-10"
                                type="submit"
                            >
                                {authenticatingUser ?<CircularProgress size={12} style={{marginRight: 5,marginTop: 5, color: '#ffffff'}} /> : null}
                                Submit
                            </button>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                </DialogActions>
                <PopupNotif2
                    activate={notifyPopUp.status}
                    messageContent={notifyPopUp.message}
                    popupType={notifyPopUp.popupType}
                    resetError={() => resetError()}
                />
            </Dialog>
        </Fragment>
    );
};

export default ConfirmPasswordChangeToken;
