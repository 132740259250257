import UserReducer from './UserReducer';
import CarouselReducer from './CarouselReducer';
import AboutReducer from './AboutReducer';
import ContactUsReducer from './ContactUsReducer';
import GalleryReducer from './GalleryReducer';
import ClientGalleryReducer from './ClientGalleryReducer';
import LoveStoryGalleryReducer from './LoveStoryGalleryReducer';
import ClientPhotosReducer from './ClientPhotosReducer';
import AuthReducer from './AuthReducer';
import LoveStoryReducer from './LoveStoryReducer';
import LoaderReducer from './LoaderReducer';
import FavouriteReducer from './FavouriteReducer';
import ResetImagesReducer from './ResetImagesReducer';
import NotificationReducer from './NotificationsReducer';
import FavouriteGalleryReducer from './FavouriteGalleryReducer';
import RefreshPhotoGalleryReducer from './RefreshPhotoGalleryReducer';
import FavouriteGalleryDetailReducer from './FavouriteGalleryDetailReducer';
import FavouritePhotoStatusesReducer from './FavouritePhotoStatusesReducer';
import PublicGalleryReducer from './PublicGalleryReducer';

export default {
  UserReducer,
  CarouselReducer,
  AboutReducer,
  ContactUsReducer,
  GalleryReducer,
  ClientGalleryReducer,
  LoveStoryGalleryReducer,
  ClientPhotosReducer,
  AuthReducer,
  LoveStoryReducer,
  LoaderReducer,
  FavouriteReducer,
  ResetImagesReducer,
  NotificationReducer,
  FavouriteGalleryReducer,
  RefreshPhotoGalleryReducer,
  FavouriteGalleryDetailReducer,
  FavouritePhotoStatusesReducer,
  PublicGalleryReducer
};
