import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const CustomDialogTitle = ({ hasCloseIcon = true, titleText, toggleModal }) => {
  return (
    <DialogTitle
      id="alert-dialog-title"
      style={{
        marginBottom: '2px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
      disableTypography>
      <Typography variant={'h3'} color={'secondary'}>
        {titleText}
      </Typography>
      {hasCloseIcon && (
        <span style={{ marginTop: '5px', marginRight: '-10px' }}>
          <Close onClick={toggleModal} />
        </span>
      )}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  hasCloseIcon: PropTypes.bool
};

export default CustomDialogTitle;
