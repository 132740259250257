import React, {Fragment, useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Slideshow from '../../components/slideShow/slideImage';

export default function ViewGalleries({ id, type, fetchGallery, data, token}) {
    const [switchAction, setSwitchAction] = useState('view');
    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;
    const dispatch = useDispatch();

    return (
        <Fragment>
            {data?.length > 0 ? (
                <Slideshow
                    token={token}
                    images={data}
                    switchAction={switchAction}
                    setSwitchAction={setSwitchAction}
                    type={type}
                    authToken={user.authToken}
                    id={id}
                    fetchGallery={fetchGallery}
                    dispatch={dispatch}
                />
            ) : (
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12} style={{height: 400, marginTop: 90}}>
                        <h4 className={'align-middle text-center text-black-50'}>
                            No gallery found
                        </h4>
                    </Grid>
                </Grid>
            )
            }
        </Fragment>
    );
}
