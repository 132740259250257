import React, {Fragment} from 'react';
import {mediaBaseUrl} from "../../../api/media_baseurl";
const Gallery = ({ data }) => {
    return (
        <Fragment>
            <section className="jophoto-gallery section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-30"><span className="section-subtitle">Our Works</span>
                            <h2 className="section-title">Gallery</h2>
                        </div>
                    </div>
                    <div className="row jophoto-photos" id="jophoto-section-photos">
                        { data?.map((gal, index) => (
                                <div className="col-md-4 animated fadeInUp" key={index}>
                                    <a href={`${mediaBaseUrl}${gal.cover_image}`} className="d-block jophoto-photo-item"
                                       data-caption="Portrait Photo" data-fancybox="gallery"> <img src={`${mediaBaseUrl}${gal.cover_image}`}
                                                                                                   alt="Image"
                                                                                                   className="img-fluid" />
                                        <div className="photo-text-more"><span className="ti-fullscreen"></span></div>
                                    </a>
                                </div>
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};
export default Gallery;
