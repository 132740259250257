import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchFavouriteApi = async data => {
  return await AxiosService.get(`${baseUrl}/userportal/favourites/`, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(res => res)
    .catch(err => err);
};

// Fetch all favourites and photos for auth user
export const fetchUserFavouriteGalleriesApi =  data => {
  return  AxiosService.get(`${baseUrl}/userportal/user-favourite-galleries/`, {
    headers: { Authorization: `${data.authToken}` }
  })
      .then(res => res)
      .catch(err => err);
};

//fetch single favourite with all photos
export const fetchUserFavouriteGalleriesDetailsApi =  data => {
  return  AxiosService.get(`${baseUrl}/userportal/user-favourite-galleries/${data.id}/`, {
    headers: { Authorization: `${data.authToken}` }
  })
      .then(res => res)
      .catch(err => err);
};

//fetch only favourites for auth user
export const fetchUserFavouriteApi = async data => {
  return await AxiosService.get(`${baseUrl}/userportal/favourites/`, {
    headers: { Authorization: `${data.authToken}` }
  })
      .then(res => res)
      .catch(err => err);
};

//fetch only favourites photo statuses for auth user
export const fetchUserFavouritePhotoStatusesApi = async data => {
  return await AxiosService.get(`${baseUrl}/userportal/favourite-photo-statuses/${data.id}`, {
    headers: { Authorization: `${data.authToken}` }
  })
      .then(res => res)
      .catch(err => err);
};

//download photos
export const downloadPhotosApi =  ({ data, onSuccess, onError }) => {
  return   AxiosService.get(`${baseUrl}/userportal/download-photos/${data.id}/`, {
    headers: { Authorization: `${data.authToken}` }
  })
      .then(onSuccess)
      .catch(onError);
};
//create favourite
export const createFavouriteApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('user', data.id);
  formData.append('description', data.description);

  return await AxiosService.post(
    `${baseUrl}/userportal/favourites/`,
    formData,
    { headers: { Authorization: `${data.authToken}` } }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

//Add photos to selected favourite
export const updateFavouriteGalleryApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  // formData.append('gallery', JSON.parse(data.gallery));
  // formData.append('favourite', data.favourite);

  return await AxiosService.post(
      `${baseUrl}/userportal/create-favourite-galleries/`,
      data,
      { headers: { Authorization: `${data.authToken}` } }
  )
      .then(res => onSuccess && onSuccess(res))
      .catch(err => onError && onError(err));
};


export const updateFavouriteApi = async ({ data, onSuccess, onError }) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('description', data.description);
  
  return await AxiosService.patch(
    `${baseUrl}/userportal/favourites/${data.id}/`,
    formData,
    { headers: { Authorization: `${data.authToken}` } }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deleteFavouriteApi =  ({ data, onSuccess, onError }) => {
  return  AxiosService.delete(
    `${baseUrl}/userportal/favourites/${data.id}/`,
    {
      headers: { Authorization: `${data.authToken}` }
    }
  )
    .then(res => onSuccess && onSuccess(res))
    .catch(err => onError && onError(err));
};

export const deletePhotoFromFavouriteApi =  ({ data, onSuccess, onError }) => {
  const arrIds = [data.gallery, data.id]
  return  AxiosService.delete(
      `${baseUrl}/userportal/remove-favourite-photo/${arrIds}/`,
      {
        headers: { Authorization: `${data.authToken}` }
      }
  )
      .then(res => onSuccess && onSuccess(res))
      .catch(err => onError && onError(err));
};

