import React, {Fragment, useEffect} from 'react';
import Loader from "../../components/loader/loader";
import NavBar from "../../components/layouts/navBar";
import SideBar from "../../components/layouts/sideBar";
import Service from "./coponents/service";
import Footer from "../../components/footer/footer";
import AboutUs from "./coponents/aboutUs";
import Gallery from "./coponents/gallery";
import {fetchCarouselRequest} from "../../redux/actions/carouselActions";
import {useDispatch, useSelector} from "react-redux";
import Testimonials from "./coponents/testimonials";
import InstagramFeed from "./coponents/instagramFeed";
import gallery from "../gallery/gallery";

const SiteHome = () => {
    const dispatch = useDispatch();
    const carouselState  = useSelector(state => state.CarouselReducer);
    const { loading, data } = carouselState;
    useEffect(() => {
        dispatch(fetchCarouselRequest())
    }, [])
    return (
        <Fragment>
               <SideBar data={data?.carousel}/>
               {/*About Us*/}
               <AboutUs data={data?.about} status={true} />
               {/*Gallery*/}
              {data?.gallery?.length > 0 &&
               <Gallery data={data?.gallery} />
              }

               {/*Services*/}
            {data?.service?.length > 0 &&
               (<Service data={data?.service} />)
            }

               {data?.testimon &&  <Testimonials/>}
               {/* <InstagramFeed /> */}
        </Fragment>
    )
}
export default SiteHome;
