import React, {Fragment, useEffect, useState} from "react";
// import ViewGalleries from "/viewGallery";
import {useDispatch, useSelector} from "react-redux";
import {fetchLoveStoryGalleryRequest} from "../../redux/actions/loveStoryGalleryActions";
import Loader from "../../components/loader/loader";
import {mediaBaseUrl} from "../../api/media_baseurl";
import ViewGalleries from "../service/viewGallery";
import  { APP_NAME } from "../../config";

const ViewLoveStoryDetails = (props) => {
    // const { id, title, type, header, cover_image, description } = props.history.location.state.data;
    const galleryState = useSelector(state => state.LoveStoryGalleryReducer);
    const authState = useSelector(state => state.AuthReducer);
    const { loading, data } = galleryState;
    const [fetchGallery, setFetchGallery] = useState();
    const { user } = authState;
    const dispatch = useDispatch();
    const [serviceData, setServiceData] = useState({
        id: null,
        title: '',
        description: {},
        cover_image: null
    });

    useEffect(() => {
        const serviceData = JSON.parse(localStorage.getItem('data'))
          if (serviceData?.id) {
             setServiceData({
                 ...serviceData,
                   id: serviceData.id,
                   title: serviceData.title,
                   description: serviceData.description,
                   cover_image: serviceData.image
             });

          }
     }, [])

    useEffect(() => {
        if ( serviceData?.id) {
            dispatch(
                fetchLoveStoryGalleryRequest({id: serviceData.id})
            );
        }

    }, [dispatch, serviceData.id]);
    return (
        <Fragment>
                <>
                    {loading ? <Loader/> : (
                        <>
                            <header className="banner-header banner-img valign bg-img" data-overlay-dark="7"
                                    data-stellar-background-ratio="0.5" style={{
                                backgroundImage: "url(" + `${mediaBaseUrl}${serviceData.cover_image}` + ")"}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7 offset-md-5 text-right caption mt-60 animate-box"
                                             data-animate-effect="fadeInUp">
                                            <h5>{'Love Story'}</h5>
                                            <h1>{serviceData.title}</h1>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <section className="section-padding">
                                <div className="container">
                                    {/*<div className="row animated fadeIn">*/}
                                    {/*    <div className="row jophoto-photos" id="jophoto-section-photos services-con">*/}
                                    {/*        <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">*/}
                                    {/*            <img src={`${mediaBaseUrl}${cover_image}`} alt="Image"*/}
                                    {/*                 className="img-fluid"/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="row animated fadeIn">
                                        <div className="col-md-6 mt-lg-5">
                                            <p>{serviceData.description?.description1}</p>
                                            <p>{serviceData.description?.description2}</p>
                                            <p>{serviceData.description?.description3}</p>
                                            <p>{serviceData.description?.description4}</p>
                                            <p>{serviceData.description?.description5}</p>
                                        </div>
                                        <div className="col-md-6 mt-lg-5  jophoto-photos"  id="jophoto-section-photos">
                                            <a href={`${mediaBaseUrl}${serviceData.cover_image}`} className="d-block jophoto-photo-item"
                                               data-caption={serviceData.title} data-fancybox="gallery">
                                                <img src={`${mediaBaseUrl}${serviceData.cover_image}`} alt="Image" className="img-fluid"/>
                                                <div className="photo-text-more"><span className="ti-fullscreen"></span>
                                                </div>
                                            </a>
                                            {/*<div className="animated fadeIn">*/}
                                            {/*    <div className="ophoto-photos" id="jophoto-section-photos">*/}
                                            {/*        <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">*/}
                                            {/*            <img src={`${mediaBaseUrl}${cover_image}`} alt="Image"*/}
                                            {/*                 className="img-fluid"/>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="row jophoto-photos mt-30" id="jophoto-section-photos">
                                        <div className="col-md-12 animated fadeInUp">
                                            <div className="row">
                                                <div className="col-md-12 mb-30"><span className="section-subtitle">{serviceData.title}</span>
                                                    {/*<h4 style={{fontSize: 28}} className="section-title">{APP_NAME}</h4>*/}
                                                </div>
                                            </div>
                                            <ViewGalleries
                                                id={serviceData.id}
                                                type={'love-story'}
                                                title={serviceData.title}
                                                fetchGallery={fetchGallery}
                                                data={data.gallery}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </>
        </Fragment>
    )
};
export default ViewLoveStoryDetails;
