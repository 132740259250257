import React, {Fragment, useEffect} from 'react';
import PageHeader from "../../components/page-header/pageHeader";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/loader/loader";
import {mediaBaseUrl} from "../../api/media_baseurl";
import {useHistory} from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import {fetchClientGalleryRequest} from "../../redux/actions/clientGalleryActions";
import {Grid} from "@material-ui/core";
import {initRequest, recoverUserAuth} from "../../utils/helper";

const ClientGalleryPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userState  = useSelector(state => state.AuthReducer);
    const { user } = userState;
    const clientGalleryState  = useSelector(state => state.ClientGalleryReducer);
    const { loading, data } = clientGalleryState;

    useEffect(() => {
        initRequest(dispatch, fetchClientGalleryRequest);
    }, [])

    const viewGallery = (id, title, image) => {
        history.push({
            pathname: '/view-client-gallery',
            search: `?gallery=${title}`,
            state: {
                data: {
                    id,
                    title,
                    type: 'client-gallery',
                    header: 'Gallery',
                    cover_image: image,
                }
            }
        });
    };

    const getServiceData = (id, title, image) => {
        const data= {id, title, image}
        localStorage.setItem('data', JSON.stringify(data));
    };

    return (
        <Fragment>
                    <PageHeader h1Text={'Gallery'} h5Text={'Your'} bgImage={`${data?.page_image}`}/>
                       <section class="section-padding">
                        <div class="container">
                            <div className="row">
                                {data?.client_gallery?.map((gallery, index) => (
                                    <div className="col-md-4 animated fadeIn"  key={index}>
                                            <div className="services-con"   style={{cursor: 'pointer'}}
                                                 onClick={() => {getServiceData(gallery.id, gallery.title,gallery.cover_image )}} key={index}>
                                                <div className="services-title">
                                                    <h4>{gallery.title}</h4>
                                                </div>
                                                <img src={`${gallery.cover_image}`} alt="" style={{width: '100%', height: 'auto'}}/>
                                                <a href={`/view-client-gallery?gallery=${gallery.title}`}></a>
                                            </div>
                                </div>
                                ))}

                                {data?.client_gallery?.length === 0 && (
                                      (
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={12} style={{height: 400, marginTop: 90}}>
                                                <h4 className={'align-middle text-center text-black-50'}>
                                                    You have no gallery yet
                                                </h4>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </div>
                        </div>
                    </section>
        </Fragment>
    )
}
export default ClientGalleryPage;
