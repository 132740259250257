import React, {Fragment, useEffect} from 'react';
import PageHeader from "../../components/page-header/pageHeader";
import {useDispatch, useSelector} from "react-redux";
import {fetchLoveStoryRequest} from "../../redux/actions/loveStoryActions";
import Loader from "../../components/loader/loader";
import {mediaBaseUrl} from "../../api/media_baseurl";
import {useHistory} from "react-router-dom";
import { Grid } from '@material-ui/core';

const LoveStoryPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loveStoryState  = useSelector(state => state.LoveStoryReducer);
    const { loading, data } = loveStoryState;
    useEffect(() => {
        dispatch(fetchLoveStoryRequest())
    }, [])

    const viewGallery = (id, title, image, description) => {
        history.push({
            pathname: '/view-love-story',
            search: `?story=${title}`,
            state: {
                data: {
                    id,
                    title,
                    type: 'love-story',
                    header: 'Love Story',
                    cover_image: image,
                    description: JSON.parse(description)
                }
            }
        });
    };

    const getServiceData = (id, title, image, description) => {
        const data= {id, title, image, description: JSON.parse(description)}
        localStorage.setItem('data', JSON.stringify(data));
    };

    return (
        <Fragment>
            <PageHeader h1Text={'Love Story'} h5Text={'Amazing couples'} bgImage={`${data?.page_image}`}/>
                <section className="jophoto-gallery section-padding mb-30">
                <div className="container">
                    <div className="row mb-20">
                        <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">
                            <h6>Welcome to Our Love Story Gallery</h6>
                        </div>
                    </div>

                    <div className="row jophoto-photos" id="jophoto-section-photos services-con">
                        {data?.love_story?.map((love, index) => (
                                <div className="col-md-6 animated fadeInUp" style={{cursor: 'pointer'}} key={index} onClick={() => {
                                    getServiceData(love.id, love.title, love.cover_image, love.description);
                                }} key={index}>
                                    {/*<a href="img/1920x1080.jpg" className="d-block jophoto-photo-item"*/}
                                    {/*   data-caption="Potrait Photo" data-fancybox="gallery">*/}
                                        <div className="services-con">
                                            <div className="services-title">
                                                <h4>{love.title}</h4>
                                            </div>
                                            <img src={`${mediaBaseUrl}${love.cover_image}`} alt="Image" className="img-fluid"/>
                                            {/*<div className="photo-text-more"><span className="ti-fullscreen"></span></div>*/}
                                            <a href={`/view-love-story?story=${love.title}`}></a>
                                        </div>
                                    {/*</a>*/}
                                </div>
                        ))}

                        {data?.love_story?.length === 0 &&

                        (
                            <Grid container spacing={4}>
                            <Grid item xs={12} md={12} style={{height: 400, marginTop: 90}}>
                                <h4 className={'align-middle text-center text-black-50'}>
                                    No stories found
                                </h4>
                            </Grid>
                        </Grid>
                        )

                        }

                    </div>
                </div>
            </section>

        </Fragment>
    )
}
export default LoveStoryPage;
