import React, {Fragment, useEffect, useState} from 'react';
import PageHeader from "../../components/page-header/pageHeader";
import {useDispatch, useSelector} from "react-redux";
import {fetchContactUsRequest} from "../../redux/actions/contactUsActions";
import Loader from "../../components/loader/loader";
import {useHistory} from "react-router-dom";
import PopupNotif2 from "../../components/notifications/popupnotif2";
import {useForm} from "react-hook-form";
import {authUser, authUserFail, authUserRequest, authUserSuccess} from "../../redux/actions/authActions";
import {CircularProgress, Typography} from "@material-ui/core";
import {AddCircle} from "@material-ui/icons";
import ResetPasswordEmail from "./modal/resetPasswordMail";
import {loginUserApi} from "../../api/authServices/authUser/loginUser";
import ResetPasswordEmailSent from "./modal/resetPasswordEmailSentMessage";

const LoginPage = (props) => {
    const dispatch = useDispatch();

    const [values, setStateValues] = useState({
        username: '',
        password: ''
    });
    const [authenticatingUser, setAuthenticatingUser] = useState(false);
    const [open, setOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);

    const [notifyPopUp, setNotifyPopUp] = useState({
        status: false,
        popupType: 'info',
        message: 'Cannot tell. Please start process again'
    });
    const authState = useSelector(state => state.AuthReducer);
    const { user } = authState;
    const history = useHistory();
    const resetError = () =>
        setNotifyPopUp({
            status: false,
            message: '',
            popupType: notifyPopUp.popupType
        });
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const handleChange = (setStateValues, values, e) => {
        const { name, value } = e.target;
        setStateValues({
            ...values,
            [name]: value
        });
    };

    const handleAuthSuccess = res => {
        localStorage.setItem(
            'user',
            JSON.stringify({
                isLoggedIn: res.data.status,
                userDetail: res.data.userDetails,
                authToken: `Token ${res.data.authToken}`
            })
        );
        history.replace('/client-gallery');
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpenMessage = () => {
        setOpenMessage(true);
    }

    const handleCloseMessage = () => {
        setOpenMessage(false);
    }

    const handleOnSubmit = async values =>  {
        setAuthenticatingUser(true);
        const { username, password } = values;
           await loginUserApi({
                data: {
                    username: username,
                    password: password
                },
                onSuccess: res => {
                    setAuthenticatingUser(false);
                    dispatch(authUserSuccess(res.data));
                    handleAuthSuccess(res);
                },
                onError: err => {
                    setAuthenticatingUser(false);
                    if(err.response) {
                        const { status } = err.response;
                        switch (status) {
                          case 400:
                            setNotifyPopUp({
                              status: true,
                              popupType: 'error',
                              message: 'Username and password does not match'
                            });
                            break;
                          case 500:
                            setNotifyPopUp({
                              status: true,
                              popupType: 'error',
                              message: 'Login failed. please try again'
                            });
                            break;
                          default:
                            setNotifyPopUp({
                              status: true,
                              popupType: 'error',
                              message: 'Login failed. please try again'
                            });
                        }
                    }

                }
            })
    };
    return (
        <Fragment>
                        <section className="section-padding mt-100">
                            <div className="container">
                                <div className="row mb-90">
                                    <div className="col-md-3 mb-30 animate-box" data-animate-effect="fadeIn">

                                    </div>
                                    <div className="col-md-6 animate-box" data-animate-effect="fadeIn">
                                         <h6>Login to access personal galleries</h6>
                                        <form  onSubmit={handleSubmit(handleOnSubmit)} className="row">
                                            <div className="col-md-12">
                                                <input
                                                    error={errors.username && true}
                                                    {...register('username', {
                                                        required: {
                                                            value: true,
                                                            message: 'Username is required'
                                                        }
                                                    })}
                                                    type="text"
                                                    name="username"
                                                    id="name"
                                                    placeholder="Username"
                                                    onChange={e =>
                                                        handleChange(setStateValues, values, e)
                                                    }
                                                />
                                                <div style={{ color: '#9C5450' }}>
                                                    <Typography
                                                        variant="caption"
                                                        gutterBottom
                                                        align="center">
                                                        {errors.username && errors.username.message}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <input
                                                    {...register('password', {
                                                        required: {
                                                            value: true,
                                                            message: 'Password is required'
                                                        }
                                                    })}
                                                    onChange={e =>
                                                        handleChange(setStateValues, values, e)
                                                    }
                                                    type="password"
                                                    name="password"
                                                    id="email"
                                                    placeholder="password"
                                                    error={errors.password && true}
                                                />
                                                <div style={{ color: '#9C5450' }}>
                                                    <Typography
                                                        variant="caption"
                                                        gutterBottom
                                                        align="center">
                                                        {errors.password && errors.password.message}
                                                    </Typography>
                                            </div>
                                                <div className="col-md-12" style={{paddingLeft: 0}}>
                                                    <label>
                                                        <a href={'javascript: void();'}
                                                           onClick={handleOpen}
                                                           type='button'
                                                           style={{color: '#9C5450', textDecoration: 'underline'}}>
                                                            Forgot password
                                                        </a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button
                                                   disabled={authenticatingUser}
                                                    style={{width: '100%'}}
                                                    className="butn-dark mt-10 float-right"
                                                    type="submit">
                                                    {authenticatingUser && <CircularProgress size={15} style={{color: 'white'}} />}
                                                    Login
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
            <PopupNotif2
                activate={notifyPopUp.status}
                messageContent={notifyPopUp.message}
                popupType={notifyPopUp.popupType}
                resetError={() => resetError()}
            />

            {open &&
            <ResetPasswordEmail
                open={open}
                handleClose={handleClose}
                handleOpenMessage={handleOpenMessage}
            />
            }
            {openMessage && (
                <ResetPasswordEmailSent
                  open={openMessage}
                  handleClose={handleCloseMessage}
                />
            )}
        </Fragment>
    )
};
export default LoginPage;