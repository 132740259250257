import { FAVOURITE_GALLERY_DETAIL } from '../actionTypes';
import { dataFetching } from './loaderAction';
import { fetchUserFavouriteGalleriesDetailsApi
} from '../../api/authServices/favourite/favourite';

export const fetchFavouriteGalleryDetail = () => ({
    type: FAVOURITE_GALLERY_DETAIL.FETCH_FAVOURITE_GALLERY_DETAIL_DATA
});

export const fetchFavouriteGalleryDetailSuccess = data => ({
    type: FAVOURITE_GALLERY_DETAIL.FETCH_FAVOURITE_GALLERY_DETAIL_SUCCESS,
    payload: {
        data
    }
});

export const fetchFavouriteGalleryDetailFail = err => ({
    type: FAVOURITE_GALLERY_DETAIL.FETCH_FAVOURITE_GALLERY_DETAIL_FAIL,
    payload: {
        err
    }
});

export const fetchFavouriteGalleryDetailRequest = data => {
    return dispatch => {
        // dispatch(dataFetching(true))
        dispatch(fetchFavouriteGalleryDetail());
        fetchUserFavouriteGalleriesDetailsApi(data)
            .then(res => {
                dispatch(fetchFavouriteGalleryDetailSuccess(res.data));
                dispatch(dataFetching(false))
            })
            .catch(err => {
                dispatch(fetchFavouriteGalleryDetailFail(err.message));
                dispatch(dataFetching(false))
            });
    };
};
