import { makeStyles } from '@material-ui/core';

const style = makeStyles(theme => ({
  formGroupLabelShrink: {
    marginTop: '15px',
    marginBottom: '-10px'
  },

  dialogRoot: {
    '& input': {
      padding: '12px',
      height: '24px'
    }
  },

  dialogPaper: {
    padding: '40px'
  },

  txtArea: {
    padding: '10px 0',
    paddingLeft: 0
  },

  dialogActionsRoot: {
    padding: 0
  }
}));

export default style;
