import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Gallery from 'react-photo-gallery';
import {mediaBaseUrl} from '../../api/media_baseurl';
import Carousel, {CarouselStyles, Modal, ModalGateway} from 'react-images';
import SelectImage from './selectedImage'
import {
    ButtonGroup,
    Tooltip, Typography
} from '@material-ui/core';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    CloudDownloadOutlined,
    Delete,
    AddToPhotos,
    FavoriteBorderOutlined,
    FavoriteBorderRounded, ShareOutlined
} from '@material-ui/icons';
import {useDispatch, useSelector} from "react-redux";
import {fetchFavouriteRequest} from "../../redux/actions/favouriteActions";
import {updateFavouriteGalleryApi} from "../../api/authServices/favourite/favourite";
import {setNotifyParams} from "../../redux/actions/notifications";
import {fetchFavouritePhotoStatusesRequest} from "../../redux/actions/favouritePhotoStatusesAction";
import {fetchFavouriteGalleryDetailRequest} from "../../redux/actions/favouriteGalleryDetail";
import { withStyles } from "@material-ui/core/styles";
import ShareGalleryForm from "../favourites/gallery-category/forms";
import CreateFavouriteForm from "../favourites/gallery-category/forms/createFavourite";

const label = {inputProps: {'aria-label': 'Checkbox demo'}};

const dropDownStyles = theme => ({

        selected: {
        backgroundColor: 'white',
        root: {
            '&$selected': {
                backgroundColor: 'orange',
            },
        }
},
});
const Slideshow = ({classes, images, type, id, token}) => {
    const [imageGallery, setImageGallery] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const favouriteState = useSelector(state => state.FavouriteReducer);
    const {loading, data} = favouriteState;
    const authState = useSelector(state => state.AuthReducer);
    const {user} = authState;
    const [favourite, setFavourite] = useState('');
    const [processingRequest, setProcessingRequest] = useState(false);
    const [editFavourite, setEditFavourite] = useState(false);
    const refreshGalleryState = useSelector(state => state.RefreshPhotoGalleryReducer);
    const { refresh } = refreshGalleryState;
    const [openShareModal, setOpenShareModal] = useState(false);
    const [galleries, setGalleries] = useState([]);
    const [clientGalleryId, setClientGalleryId] = useState(null)


    // useEffect(() => {
    //     const fav = localStorage.getItem('fav_id')
    //     if (fav) {
    //         localStorage.removeItem('fav_id');
    //     }
    // }, [])

    const handleOpen = () => {
        setOpen(true);
    }

    const handleOpenShareModal = (galleryId) => {
        setGalleries(galleryId);
        let clientGalleryId = id;
        if (type === 'favourite-detail') {
            clientGalleryId = localStorage.getItem('favClientGalleryId');
        }
        setClientGalleryId(clientGalleryId)
        setOpenShareModal(true);

    }

    const handleCloseShareModal = () => {
        setOpenShareModal(false);
    }

    const handleClose = () => {
        setOpen(false);
        // dispatch(resetImages(true));

        // const selImages = JSON.parse(localStorage.getItem('selectedImages'))
        // if (selImages) {
        //   localStorage.removeItem('selectedImages');
        //   if (selectAll) {
        //       setSelectAll(false);
        //   }
        //
        // }
    }

    useEffect(() => {
        const favouriteId = localStorage.getItem('fav_id')

        if (favouriteId){
            setFavourite(favouriteId)
        }
    }, [favourite])

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        getAllSelectedImageIds(selectAll);
    };

    const getAllSelectedImageIds = () => {
        const ids = [];
            for (let i = 0; i < imageGallery?.length; i++) {
                ids.push(imageGallery[i].id);
            }
            localStorage.setItem('selectedImages', JSON.stringify(ids));
    }

    const imageRenderer = useCallback(
        ({index, left, top, key, photo}) => {
            return (
                <SelectImage
                    selected={selectAll}
                    key={key}
                    margin={'2px'}
                    index={index}
                    photo={photo}
                    left={left}
                    top={top}
                    setViewerIsOpen={setViewerIsOpen}
                    setCurrentImage={setCurrentImage}
                    addPhotoToGallery={addPhotoToGallery}
                    type={type}
                    handleGalleryFilterAfterDelete={handleGalleryFilterAfterDelete}
                    imageGallery={imageGallery}
                    typeId={id}
                    handleOpenShareModal={handleOpenShareModal}
                    token={token}
        />
            );
        },
        [selectAll]
    );
    // image selection code ends here

    const openLightbox = useCallback((event, {photo, index}) => {
        setCurrentImage(index);
        setViewerIsOpen(true);

    }, []);


    useEffect(() => {
        if (type === 'favourite') {
            formatFavouriteData();
        } else if (type === 'favourite-detail') {
            gallery();
        } else {
            gallery();

        }
    }, []);


    const formatFavouriteData = () => {
        const myGallery = [];

        if (images?.length > 0) {
            images.map((image) => {
                myGallery.push({
                    id: image.id,
                    title: image.name,
                    description: image.description,
                    favCount: image.galleries?.length,
                    src: image.galleries?.length > 0 ?`${mediaBaseUrl}/media/${image.galleries[0]?.land_scape_img}` : "img/fav2_holder.jpg",
                    width: image.galleries?.length > 0 ? image.galleries[0]?.img_width : 640,
                    height: image.galleries?.length > 0 ? image.galleries[0]?.img_height : 360,
                    time_created: image.time_created
                });
            })
        }
        setItems(myGallery);
        setImageGallery(myGallery);
        localStorage.setItem('gallery', JSON.stringify(myGallery));
    }

    const handleGalleryFilterAfterDelete = (id) => {
        const gallery = JSON.parse(localStorage.getItem('gallery'));
        if (gallery?.length > 0) {
            const newData = gallery.filter((x, index) => {
              return   x.id !== id
            });
            setImageGallery(newData);
            localStorage.setItem('gallery', JSON.stringify(newData));
        }
    }

    const gallery = () => {
        const myGallery = [];
        if (images?.length > 0) {
            images.map(image => {
                myGallery.push({
                    id: image.id,
                    src: `${mediaBaseUrl}/media/${image.land_scape_img}`,
                    width: image.img_width,
                    height: image.img_height,
                    time_updated: image.time_updated
                });
            });
        }
        setItems(myGallery);
        setImageGallery(myGallery);
        if (type === 'favourite-detail') {
            localStorage.setItem('gallery', JSON.stringify(myGallery));
        }
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const handleIsImageSelected = () => {
        const imgSel = JSON.parse(localStorage.getItem('selectedImages'))
        return imgSel?.length > 0;
    }

    const handleChange = (event) => {
        setFavourite(event.target.value);

        dispatch(fetchFavouriteGalleryDetailRequest({
            authToken: user.authToken,
            id: event.target.value
        }));
        localStorage.setItem('fav_id', event.target.value)

    };

    const handleFavouriteSelectedValidations = (favId) => {
        if (favId === null) {
            dispatch(
                setNotifyParams({
                    status: true,
                    message: 'Please select a favourite',
                    type: 'error'
                })
            );
            return false
        }
        return true;
    }

    const addPhotoToGallery = async (photoId) => {
        const favouriteId = localStorage.getItem('fav_id')
        //check if a favourite is selected
        if (handleFavouriteSelectedValidations(favouriteId)) {
            setProcessingRequest(true);
            const data = {
                favourite: favouriteId,
                gallery: [photoId],
                user: user.userDetail.id,
                authToken: user.authToken
            };
            //add a favourite
            await updateFavouriteGalleryApi({
                data,
                onSuccess: res => {
                    setProcessingRequest(false);
                    dispatch(
                        fetchFavouriteRequest({
                            authToken: user.authToken,
                            id: user.userDetail.id
                        })
                    );

                    dispatch(fetchFavouriteGalleryDetailRequest({
                        authToken: user.authToken,
                        id: favouriteId
                    }));

                    setProcessingRequest(false);
                    dispatch(
                        setNotifyParams({
                            status: true,
                            message: res.data.message,
                            type: 'success'
                        })
                    );
                },
                onError: err => {
                    const {data, status} = err?.response;
                    switch (status) {
                        case 400:
                            setProcessingRequest(false);
                            handleFavResValidationErrors(data);
                            break;
                        case 500:
                            setProcessingRequest(false);
                            dispatch(
                                setNotifyParams({
                                    status: true,
                                    message: 'Favourite update failed',
                                    type: 'error'
                                })
                            )
                            break;
                        default:
                            setProcessingRequest(false);
                            dispatch(
                                setNotifyParams({
                                    status: true,
                                    message: 'Favourite update failed',
                                    type: 'error'
                                })
                            )
                    }
                }
            });
        }
    };

const handleFavResValidationErrors = data => {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('favourite')) {
        dispatch(setNotifyParams({
            status: true,
            message: data.favourite[0],
            type: 'error'
        }))
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (data.hasOwnProperty('gallery')) {
        dispatch(setNotifyParams({
            status: true,
            message: data.gallery[0],
            type: 'error'
        }))
    } else {
        dispatch(setNotifyParams({
            status: true,
            message: 'Failed to process request. Try again',
            type: 'error'
        }))
    }
};


    return (
        <Fragment>
            <>
                {type === 'client-gallery' && (
                    <div className='row'>
                        <div className='col-md-3'>
                            {/*<h6>*/}
                            {/*Select all photos*/}
                            {/*<Tooltip title={'Select All'}>*/}
                            {/*    <Checkbox checked={selectAll} style={{color: 'white'}} onClick={toggleSelectAll} {...label} />*/}
                            {/*</Tooltip>*/}
                            <InputLabel style={{borderColor: "black"}}
                                        id="demo-simple-select-label"
                                        className={'text-white'}>
                                <Typography variant={'caption'}>Select a favourite</Typography>
                            </InputLabel>
                            <Box sx={{minWidth: 120, background: 'grey', border: 'none', borderRadius: 1}}>
                                <FormControl fullWidth>

                                    <Select style={{borderColor: 'white'}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue={'hi'}
                                            value={favourite}
                                            // label={"Select a favourite"}
                                            displayEmpty
                                            onChange={handleChange}
                                    >
                                        {data?.length > 0 ?data?.map((fav, index) => (
                                            <MenuItem value={fav.id} key={index}
                                                      style={{cursor: 'pointer'}}
                                            >
                                                <span>{fav.name}</span>
                                            </MenuItem>
                                        )):  <MenuItem disabled>No favourites</MenuItem>}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div className={'col-md-1'}>
                            {/*<Tooltip title={'Create favourite'} style={{cursor: 'pointer', marginTop:10}} onClick={handleOpen}>*/}
                            {/*    <AddToPhotos/>*/}
                            {/*</Tooltip>*/}
                        </div>
                        <div className={'col-md-2'}></div>
                        <div className='col-md-6'>
                            <div style={{
                                position: 'relative',
                                background: 'rgba(16,16,16,0.2)',
                                bottom: 0,
                                left: 0,
                                textAlign: 'right',
                                width: '100%',
                                paddingRight: 20
                            }}>
                                <ButtonGroup style={{paddingTop: 10}}>
                                    <Tooltip title={'Create favourite'} style={{cursor: 'pointer'}} onClick={handleOpen}>
                                        <AddToPhotos/>
                                    </Tooltip>
                                    <a href="/favourites" className="blog-img">
                                        <Tooltip title="View Favourites">
                                            <FavoriteBorderRounded style={{color: '#9B5450', cursor: 'pointer'}}/>
                                        </Tooltip>
                                    </a>
                                    {/*<Tooltip title="Add to favourite">*/}
                                    {/*        <FavoriteBorderOutlined  onClick={handleOpen}*/}
                                    {/*                                style={{color: 'white', cursor: 'pointer'}}/>*/}
                                    <a href="javascript: void(0);" className="blog-img">
                                        <Tooltip title="Share All" onClick={() => {
                                            getAllSelectedImageIds();
                                            const ids = JSON.parse(localStorage.getItem('selectedImages'));
                                            handleOpenShareModal(ids)
                                        }}>
                                            <ShareOutlined style={{color: '#ffffff', cursor: 'pointer'}}/>
                                        </Tooltip>
                                    </a>
                                    {/*</Tooltip>*/}
                                    {/*<Tooltip title="Share All" style={{marginRight: 15}}>*/}
                                    {/*    <ShareOutlined*/}
                                    {/*        style={{*/}
                                    {/*            color: '#ffffff'*/}
                                    {/*        }}*/}
                                    {/*        onClick={() => {*/}
                                    {/*            // addPhotoToGallery(photo.id);*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</Tooltip>*/}
                                    <Tooltip title="Download All">
                                        <a href={`${mediaBaseUrl}/userportal/download-photos/user/${user.userDetail.id}/photo/${id}/`}>
                                            <CloudDownloadOutlined style={{color: 'white', cursor: 'pointer'}}/>
                                        </a>
                                    </Tooltip>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                )}

                {type === 'favourite-detail' && (
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className={'col-md-1'}>
                        </div>
                        <div className={'col-md-2'}></div>
                        <div className='col-md-6'>
                            <div style={{
                                position: 'relative',
                                background: 'rgba(16,16,16,0.2)',
                                bottom: 0,
                                left: 0,
                                textAlign: 'right',
                                width: '100%',
                                paddingRight: 20
                            }}>
                                <ButtonGroup style={{paddingTop: 10}}>
                                    <a href="javascript: void(0);" className="blog-img">
                                        <Tooltip title="Share All" onClick={() => {
                                            getAllSelectedImageIds();
                                            const ids = JSON.parse(localStorage.getItem('selectedImages'));
                                            handleOpenShareModal(ids)
                                        }}>
                                            <ShareOutlined style={{color: '#ffffff', cursor: 'pointer'}}/>
                                        </Tooltip>
                                    </a>
                                    <Tooltip title="Download All">
                                        <a href={`${mediaBaseUrl}/userportal/download-favourite-photos/user/${user.userDetail.id}/photo/${id}/`}>
                                            <CloudDownloadOutlined style={{color: 'white', cursor: 'pointer'}}/>
                                        </a>
                                    </Tooltip>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                )}

                {type === 'public-detail' && (
                    <div className='row'>
                        <div className='col-md-3'>
                        </div>
                        <div className={'col-md-1'}>
                        </div>
                        <div className={'col-md-2'}></div>
                        <div className='col-md-6'>
                            <div style={{
                                position: 'relative',
                                background: 'rgba(16,16,16,0.2)',
                                bottom: 0,
                                left: 0,
                                textAlign: 'right',
                                width: '100%',
                                paddingRight: 20
                            }}>
                                <ButtonGroup style={{paddingTop: 10}}>
                                    <Tooltip title="Download All">
                                        <a href={`${mediaBaseUrl}/photogallery/download-photos/photo/${token}/`}>
                                            <CloudDownloadOutlined style={{color: 'white', cursor: 'pointer'}}/>
                                        </a>
                                    </Tooltip>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                )}

                {
                    type === 'love-story' || type === 'service' ?
                        (<Gallery
                        photos={imageGallery}
                        onClick={openLightbox}
                        direction={'column'}
                        columns={4}
                        margin={4}
                    />)
                        :
                        (<Gallery
                            photos={imageGallery}
                            renderImage={imageRenderer}
                            onClick={openLightbox}
                            direction={type === 'love-story' || type === 'service' ? 'column' : 'row'}
                            columns={type === 'love-story' || type === 'service' ? 4 : ''}
                            // margin={4}
                        />)
                }
            </>
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            getStyles={{pointerEvents: 'none'}}
                            currentIndex={currentImage}
                            views={imageGallery.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>

            {open && (
                <CreateFavouriteForm
                    open={open}
                    handleClose={handleClose}
                    editCategory={editFavourite}
                    selectedImages={JSON.parse(localStorage.getItem('selectedImages'))}
                    setEditCategory={setEditFavourite}
                />
            )}

            {
                openShareModal && (
                    <ShareGalleryForm
                        open={openShareModal}
                        handleClose={handleCloseShareModal}
                        galleries={galleries}
                        clientGalleryId={clientGalleryId}
                    />
                )
            }
        </Fragment>
    );
};

export default Slideshow;
