import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const createUserEnquariesApi = ({ data, onSuccess, onError }) => {
  return  AxiosService.post(`${baseUrl}/userportal/user-enquiries/`, data, {
    headers: { Authorization: `${data.authToken}` }
  })
    .then(onSuccess)
    .catch(onError);
};
