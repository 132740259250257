import AxiosService from '../axiosService';
import baseUrl from '../BaseUrl';

export const fetchCarouselApi = async () => {
    return await AxiosService.get(
        `${baseUrl}/userportal/home-page-content/`)
        .then(res => res)
        .catch(err => err);
};

