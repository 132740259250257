import React, {Fragment} from 'react';
import {mediaBaseUrl} from "../../api/media_baseurl";
const PageHeader = ({ h5Text, h1Text, bgImage}) => {
    return (
        <Fragment>
            {bgImage && (
                <header className="banner-header banner-img valign bg-img" data-overlay-dark="7"
                         data-stellar-background-ratio="0.5"  style={{
                    backgroundImage: "url(" + `${mediaBaseUrl}${bgImage}` + ")"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 offset-md-5 text-right caption mt-60 animate-box"
                                 data-animate-effect="fadeInUp">
                                <h5>{h5Text}</h5>
                                <h1>{h1Text}</h1>
                            </div>
                        </div>
                    </div>
                </header>
            )
            }
        </Fragment>


    )
}
export default PageHeader;
