import { GALLERY } from '../actionTypes';
import { fetchCategoryGalleryApi } from '../../api/authServices/manageGalleries/galleries';
import {fetchLoveStoryGalleryApi} from "../../api/authServices/loveStories/loveStories";
import {
  fetchLoveStoryGallery,
  fetchLoveStoryGalleryFail,
  fetchLoveStoryGallerySuccess
} from "./loveStoryGalleryActions";
import { dataFetching } from './loaderAction';

export const fetchGallery = () => ({
  type: GALLERY.FETCH_GALLERY_DATA
});

export const fetchGallerySuccess = data => ({
  type: GALLERY.FETCH_GALLERY_SUCCESS,
  payload: {
    data
  }
});

export const fetchGalleryFail = err => ({
  type: GALLERY.FETCH_GALLERY_FAIL,
  payload: {
    err
  }
});

export const fetchCategoryGalleryRequest = data => {
  return dispatch => {
    dispatch(dataFetching(true))
    dispatch(fetchGallery());
    fetchCategoryGalleryApi(data)
      .then(res => {
        dispatch(dataFetching(false))
        dispatch(fetchGallerySuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchGalleryFail(err));
        dispatch(dataFetching(false))
      });
  };
};

export const fetchLoveStoryGalleryRequest = data => {
  return dispatch => {
    dispatch(fetchLoveStoryGallery());
    fetchLoveStoryGalleryApi(data)
      .then(res => {
        dispatch(fetchLoveStoryGallerySuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchLoveStoryGalleryFail(err.message));
      });
  };
};

