import { REFRESH_GALLERY } from '../redux/actionTypes';

const initialState = {
    gallery: {},
};

export default function refreshGalleryReducer(state = initialState, action) {
    switch (action.type) {
        case REFRESH_GALLERY.REFRESH_PHOTO_GALLERY:
            return {
                ...state,
                gallery: action.payload.data
            };
        default:
            return state;
    }
}
