import { LOVE_STORY_GALLERY } from '../actionTypes';
import { fetchLoveStoryGalleryApi} from '../../api/authServices/loveStories/loveStories';
import { dataFetching } from './loaderAction';

//Get all gallery links
export const fetchLoveStoryGallery = () => ({
  type: LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_DATA
});

export const fetchLoveStoryGallerySuccess = data => ({
  type: LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_SUCCESS,
  payload: {
    data
  }
});

export const fetchLoveStoryGalleryFail = err => ({
  type: LOVE_STORY_GALLERY.FETCH_LOVE_STORY_GALLERY_FAIL,
  payload: {
    err
  }
});

export const fetchLoveStoryGalleryRequest = data => {
  return dispatch => {
    dispatch(dataFetching(true))
    dispatch(fetchLoveStoryGallery());
    fetchLoveStoryGalleryApi(data)
      .then(res => {
        dispatch(fetchLoveStoryGallerySuccess(res.data));
        dispatch(dataFetching(false))
      })
      .catch(err => {
        dispatch(fetchLoveStoryGalleryFail(err.message));
        dispatch(dataFetching(false))

      });
  };
};
