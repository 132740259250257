import { ABOUT } from '../actionTypes';
import {fetchCarouselApi} from "../../api/authServices/carousel/carousel";
import {fetchAboutPageContentApi} from "../../api/authServices/aboutPage/aboutPage";
import { dataFetching } from './loaderAction';

export const fetchAbout = () => ({
    type: ABOUT.FETCH_ABOUT_DATA
});

export const fetchAboutSuccess = data => ({
    type: ABOUT.FETCH_ABOUT_SUCCESS,
    payload: {
        data
    }
});

export const fetchAboutFail = err => ({
    type: ABOUT.FETCH_ABOUT_FAIL,
    payload: {
        err
    }
});

export const fetchAboutRequest = () => {
    return dispatch => {
        dispatch(fetchAbout());
        dispatch(dataFetching(true))
        fetchAboutPageContentApi()
            .then(res => {
                dispatch(fetchAboutSuccess(res.data));
                dispatch(dataFetching(false));

            })
            .catch(err => {
                dispatch(fetchAboutFail(err));
            });
    };
};
