import { PUBLIC_GALLERY } from '../actionTypes';
import { dataFetching } from './loaderAction';
import {fetchPublicGalleryApi} from "../../api/authServices/publicGallery/publicGallery";

export const fetchPublicGalleryData = () => ({
    type: PUBLIC_GALLERY.FETCH_PUBLIC_GALLERY_DATA
});

export const fetchPublicGallerySuccess = data => ({
    type: PUBLIC_GALLERY.FETCH_PUBLIC_GALLERY_SUCCESS,
    payload: {
        data
    }
});

export const fetchPublicGalleryFail = err => ({
    type: PUBLIC_GALLERY.FETCH_PUBLIC_GALLERY_FAIL
});

export const fetchPubicGalelryRequest = data => {
    return dispatch => {
        dispatch(dataFetching(true))
        dispatch(fetchPublicGalleryData());
        fetchPublicGalleryApi(data)
            .then(res => {
                dispatch(fetchPublicGallerySuccess(res.data));
                dispatch(dataFetching(false));
            })
            .catch(err => {
                dispatch(fetchPublicGalleryFail(err.message));
                dispatch(dataFetching(false))
            });
    };
};
