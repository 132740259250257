import { AUTH } from '../actionTypes';
import { loginUserApi } from '../../api/authServices/authUser/loginUser';

//Authenticate user
export const authUser = () => ({
  type: AUTH.AUTH_USER
});

export const authUserSuccess = data => ({
  type: AUTH.AUTH_USER_SUCCESS,
  payload: {
    data
  }
});
export const storeAuthUser = () => ({
  type: AUTH.STORE_LOGIN_USER
});
export const recoverAuth = data => ({
  type: AUTH.RECOVER_AUTH,
  payload: {
    data
  }
});
export const setUserState = data => ({
  type: AUTH.IS_USER_LOGGED_IN,
  payload: {
    data
  }
});
export const setUserToken = data => ({
  type: AUTH.SET_USER_TOKEN,
  payload: {
    data
  }
});
export const setUserMaxTimeOut = data => ({
  type: AUTH.SET_MAX_TIME_OUT,
  payload: {
    data
  }
});
export const fetchUpdatedUser = data => ({
  type: AUTH.FETCH_UPDATED_USER,
  payload: {
    data
  }
});

export const logoutUser = () => ({
  type: AUTH.LOGOUT_USER
});

export const authUserFail = err => ({
  type: AUTH.AUTH_USER_FAIL,
  payload: {
    err
  }
});

export const authUserRequest = ({ data, onSuccess, onError }) => {
  return dispatch => {
    dispatch(authUser());
    loginUserApi(data)
      .then(res => {
        dispatch(authUserSuccess(res.data));
        onSuccess(res);
      })
      .catch(err => {
        dispatch(authUserFail(err));
        onError(err);
      });
  };
};

// export const fetchUserDetailsRequest = ({ data, onSuccess, onError }) => {
//   return dispatch => {
//     dispatch(authUser());
//     loginUser
//       .fetchUserUpdatedDetails(data)
//       .then(res => {
//         onSuccess(res);
//       })
//       .catch(err => {
//         dispatch(authUserFail(err));
//       });
//   };
// };
