const  routes = {
   HOME: '/home',
   ABOUT_US: '/about-us',
   VIEW_LOVE_STORY: '/view-love-story',
   VIEW_CLIENT_GALLERY: '/view-client-gallery',
   VIEW_GALLERY: '/view-gallery',
   CLIENT_GALLERY: '/client-gallery',
   FAVOURITE_DETAILS: '/favourite-details',
   FAVOURITES: '/favourites',
   CONTACT_US: '/contact-us',
   LOVE_STORY: '/love-story',
   AUTH_LOGIN: '/auth-login',
   AUTH_CHANGE_PASSWORD: '/auth-change-password',
   PHOTOGALLERY: '/photogallery/:gallery',
   BLOG:'/blog',
   PAGE_NOT_FOUND: '/page-not-found',
   RESET_PASSWORD_FORM: '/auth-password-reset',
   SEE_GALLERIES: '/galleries',
}

export default routes;
